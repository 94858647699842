*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

:root, [data-theme] {
  background-color: hsl(var(--b1) / var(--tw-bg-opacity, 1));
  color: hsl(var(--bc) / var(--tw-text-opacity, 1));
}

html {
  -webkit-tap-highlight-color: transparent;
}

:root {
  color-scheme: dark;
  --pf: 198 93% 53%;
  --sf: 234 89% 67%;
  --af: 329 86% 63%;
  --b2: 222 47% 4%;
  --b3: 0 0% 0%;
  --bc: 229 7% 80%;
  --pc: 202 34% 14%;
  --sc: 239 22% 15%;
  --ac: 332 26% 15%;
  --nc: 221 7% 82%;
  --suc: 169 31% 13%;
  --wac: 39 36% 14%;
  --erc: 354 28% 15%;
  --rounded-box: 1rem;
  --rounded-btn: .5rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-text-case: uppercase;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: 198 93% 60%;
  --s: 234 89% 74%;
  --a: 329 86% 70%;
  --n: 217 33% 17%;
  --nf: 217 30% 22%;
  --b1: 222 47% 11%;
  --in: 198 90% 48%;
  --inc: 0 0% 0%;
  --su: 172 66% 50%;
  --wa: 41 88% 64%;
  --er: 351 95% 71%;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.prose {
  color: var(--tw-prose-body);
  max-width: 65ch;
}

.prose :where(p):not(:where([class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

.prose :where([class~="lead"]):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-lead);
  margin-top: 1.2em;
  margin-bottom: 1.2em;
  font-size: 1.25em;
  line-height: 1.6;
}

.prose :where(a):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-links);
  font-weight: 500;
  text-decoration: underline;
}

.prose :where(strong):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-bold);
  font-weight: 600;
}

.prose :where(a strong):not(:where([class~="not-prose"] *)), .prose :where(blockquote strong):not(:where([class~="not-prose"] *)), .prose :where(thead th strong):not(:where([class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(ol):not(:where([class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  padding-left: 1.625em;
  list-style-type: decimal;
}

.prose :where(ol[type="A"]):not(:where([class~="not-prose"] *)) {
  list-style-type: upper-alpha;
}

.prose :where(ol[type="a"]):not(:where([class~="not-prose"] *)) {
  list-style-type: lower-alpha;
}

.prose :where(ol[type="A" s]):not(:where([class~="not-prose"] *)) {
  list-style-type: upper-alpha;
}

.prose :where(ol[type="a" s]):not(:where([class~="not-prose"] *)) {
  list-style-type: lower-alpha;
}

.prose :where(ol[type="I"]):not(:where([class~="not-prose"] *)) {
  list-style-type: upper-roman;
}

.prose :where(ol[type="i"]):not(:where([class~="not-prose"] *)) {
  list-style-type: lower-roman;
}

.prose :where(ol[type="I" s]):not(:where([class~="not-prose"] *)) {
  list-style-type: upper-roman;
}

.prose :where(ol[type="i" s]):not(:where([class~="not-prose"] *)) {
  list-style-type: lower-roman;
}

.prose :where(ol[type="1"]):not(:where([class~="not-prose"] *)) {
  list-style-type: decimal;
}

.prose :where(ul):not(:where([class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  padding-left: 1.625em;
  list-style-type: disc;
}

.prose :where(ol > li):not(:where([class~="not-prose"] *))::marker {
  color: var(--tw-prose-counters);
  font-weight: 400;
}

.prose :where(ul > li):not(:where([class~="not-prose"] *))::marker {
  color: var(--tw-prose-bullets);
}

.prose :where(hr):not(:where([class~="not-prose"] *)) {
  border-color: var(--tw-prose-hr);
  border-top-width: 1px;
  margin-top: 3em;
  margin-bottom: 3em;
}

.prose :where(blockquote):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-quotes);
  border-left-width: .25rem;
  border-left-color: var(--tw-prose-quote-borders);
  quotes: "“""”""‘""’";
  margin-top: 1.6em;
  margin-bottom: 1.6em;
  padding-left: 1em;
  font-style: italic;
  font-weight: 500;
}

.prose :where(blockquote p:first-of-type):not(:where([class~="not-prose"] *)):before {
  content: open-quote;
}

.prose :where(blockquote p:last-of-type):not(:where([class~="not-prose"] *)):after {
  content: close-quote;
}

.prose :where(h1):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 0;
  margin-bottom: .888889em;
  font-size: 2.25em;
  font-weight: 800;
  line-height: 1.11111;
}

.prose :where(h1 strong):not(:where([class~="not-prose"] *)) {
  color: inherit;
  font-weight: 900;
}

.prose :where(h2):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 2em;
  margin-bottom: 1em;
  font-size: 1.5em;
  font-weight: 700;
  line-height: 1.33333;
}

.prose :where(h2 strong):not(:where([class~="not-prose"] *)) {
  color: inherit;
  font-weight: 800;
}

.prose :where(h3):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.6em;
  margin-bottom: .6em;
  font-size: 1.25em;
  font-weight: 600;
  line-height: 1.6;
}

.prose :where(h3 strong):not(:where([class~="not-prose"] *)) {
  color: inherit;
  font-weight: 700;
}

.prose :where(h4):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.5em;
  margin-bottom: .5em;
  font-weight: 600;
  line-height: 1.5;
}

.prose :where(h4 strong):not(:where([class~="not-prose"] *)) {
  color: inherit;
  font-weight: 700;
}

.prose :where(img):not(:where([class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose :where(figure > *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.prose :where(figcaption):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-captions);
  margin-top: .857143em;
  font-size: .875em;
  line-height: 1.42857;
}

.prose :where(code):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-code);
  font-size: .875em;
  font-weight: 600;
}

.prose :where(code):not(:where([class~="not-prose"] *)):before, .prose :where(code):not(:where([class~="not-prose"] *)):after {
  content: "`";
}

.prose :where(a code):not(:where([class~="not-prose"] *)), .prose :where(h1 code):not(:where([class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(h2 code):not(:where([class~="not-prose"] *)) {
  color: inherit;
  font-size: .875em;
}

.prose :where(h3 code):not(:where([class~="not-prose"] *)) {
  color: inherit;
  font-size: .9em;
}

.prose :where(h4 code):not(:where([class~="not-prose"] *)), .prose :where(blockquote code):not(:where([class~="not-prose"] *)), .prose :where(thead th code):not(:where([class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(pre):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-pre-code);
  background-color: var(--tw-prose-pre-bg);
  border-radius: .375rem;
  margin-top: 1.71429em;
  margin-bottom: 1.71429em;
  padding: .857143em 1.14286em;
  font-size: .875em;
  font-weight: 400;
  line-height: 1.71429;
  overflow-x: auto;
}

.prose :where(pre code):not(:where([class~="not-prose"] *)) {
  font-weight: inherit;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  background-color: #0000;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

.prose :where(pre code):not(:where([class~="not-prose"] *)):before, .prose :where(pre code):not(:where([class~="not-prose"] *)):after {
  content: none;
}

.prose :where(table):not(:where([class~="not-prose"] *)) {
  table-layout: auto;
  text-align: left;
  width: 100%;
  margin-top: 2em;
  margin-bottom: 2em;
  font-size: .875em;
  line-height: 1.71429;
}

.prose :where(thead):not(:where([class~="not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-th-borders);
}

.prose :where(thead th):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  vertical-align: bottom;
  padding-bottom: .571429em;
  padding-left: .571429em;
  padding-right: .571429em;
  font-weight: 600;
}

.prose :where(tbody tr):not(:where([class~="not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-td-borders);
}

.prose :where(tbody tr:last-child):not(:where([class~="not-prose"] *)) {
  border-bottom-width: 0;
}

.prose :where(tbody td):not(:where([class~="not-prose"] *)) {
  vertical-align: baseline;
}

.prose :where(tfoot):not(:where([class~="not-prose"] *)) {
  border-top-width: 1px;
  border-top-color: var(--tw-prose-th-borders);
}

.prose :where(tfoot td):not(:where([class~="not-prose"] *)) {
  vertical-align: top;
}

.prose {
  --tw-prose-body: #374151;
  --tw-prose-headings: #111827;
  --tw-prose-lead: #4b5563;
  --tw-prose-links: #111827;
  --tw-prose-bold: #111827;
  --tw-prose-counters: #6b7280;
  --tw-prose-bullets: #d1d5db;
  --tw-prose-hr: #e5e7eb;
  --tw-prose-quotes: #111827;
  --tw-prose-quote-borders: #e5e7eb;
  --tw-prose-captions: #6b7280;
  --tw-prose-code: #111827;
  --tw-prose-pre-code: #e5e7eb;
  --tw-prose-pre-bg: #1f2937;
  --tw-prose-th-borders: #d1d5db;
  --tw-prose-td-borders: #e5e7eb;
  --tw-prose-invert-body: #d1d5db;
  --tw-prose-invert-headings: #fff;
  --tw-prose-invert-lead: #9ca3af;
  --tw-prose-invert-links: #fff;
  --tw-prose-invert-bold: #fff;
  --tw-prose-invert-counters: #9ca3af;
  --tw-prose-invert-bullets: #4b5563;
  --tw-prose-invert-hr: #374151;
  --tw-prose-invert-quotes: #f3f4f6;
  --tw-prose-invert-quote-borders: #374151;
  --tw-prose-invert-captions: #9ca3af;
  --tw-prose-invert-code: #fff;
  --tw-prose-invert-pre-code: #d1d5db;
  --tw-prose-invert-pre-bg: #00000080;
  --tw-prose-invert-th-borders: #4b5563;
  --tw-prose-invert-td-borders: #374151;
  font-size: 1rem;
  line-height: 1.75;
}

.prose :where(video):not(:where([class~="not-prose"] *)), .prose :where(figure):not(:where([class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose :where(li):not(:where([class~="not-prose"] *)) {
  margin-top: .5em;
  margin-bottom: .5em;
}

.prose :where(ol > li):not(:where([class~="not-prose"] *)), .prose :where(ul > li):not(:where([class~="not-prose"] *)) {
  padding-left: .375em;
}

.prose :where(.prose > ul > li p):not(:where([class~="not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.prose :where(.prose > ul > li > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 1.25em;
}

.prose :where(.prose > ul > li > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 1.25em;
}

.prose :where(.prose > ol > li > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 1.25em;
}

.prose :where(.prose > ol > li > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 1.25em;
}

.prose :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.prose :where(hr + *):not(:where([class~="not-prose"] *)), .prose :where(h2 + *):not(:where([class~="not-prose"] *)), .prose :where(h3 + *):not(:where([class~="not-prose"] *)), .prose :where(h4 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose :where(thead th:first-child):not(:where([class~="not-prose"] *)) {
  padding-left: 0;
}

.prose :where(thead th:last-child):not(:where([class~="not-prose"] *)) {
  padding-right: 0;
}

.prose :where(tbody td, tfoot td):not(:where([class~="not-prose"] *)) {
  padding: .571429em;
}

.prose :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="not-prose"] *)) {
  padding-left: 0;
}

.prose :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-prose"] *)) {
  padding-right: 0;
}

.prose :where(.prose > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose :where(.prose > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 0;
}

.alert {
  text-align: center;
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: hsl(var(--b2) / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity));
  border-radius: var(--rounded-box, 1rem);
  --alert-bg: hsl(var(--b2));
  --alert-bg-mix: hsl(var(--b1));
  background-color: var(--alert-bg);
  grid-auto-flow: row;
  align-content: flex-start;
  place-items: center;
  gap: 1rem;
  width: 100%;
  padding: 1rem;
  display: grid;
}

@media (width >= 640px) {
  .alert {
    text-align: left;
    grid-template-columns: auto minmax(auto, 1fr);
    grid-auto-flow: column;
    justify-items: start;
  }
}

.artboard {
  width: 100%;
}

.avatar {
  display: inline-flex;
  position: relative;
}

.avatar > div {
  aspect-ratio: 1;
  display: block;
  overflow: hidden;
}

.avatar img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.avatar.placeholder > div {
  justify-content: center;
  align-items: center;
  display: flex;
}

.badge {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: hsl(var(--b2) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b1) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity));
  border-radius: var(--rounded-badge, 1.9rem);
  justify-content: center;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  height: 1.25rem;
  padding-left: .563rem;
  padding-right: .563rem;
  font-size: .875rem;
  line-height: 1.25rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
  display: inline-flex;
}

.btm-nav {
  padding-bottom: env(safe-area-inset-bottom);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b1) / var(--tw-bg-opacity));
  color: currentColor;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 4rem;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.btm-nav > * {
  cursor: pointer;
  border-color: currentColor;
  flex-direction: column;
  flex-basis: 100%;
  justify-content: center;
  align-items: center;
  gap: .25rem;
  height: 100%;
  display: flex;
  position: relative;
}

.breadcrumbs {
  max-width: 100%;
  padding-top: .5rem;
  padding-bottom: .5rem;
  overflow-x: auto;
}

.breadcrumbs > ul, .breadcrumbs > ol {
  white-space: nowrap;
  align-items: center;
  min-height: min-content;
  display: flex;
}

.breadcrumbs > ul > li, .breadcrumbs > ol > li {
  align-items: center;
  display: flex;
}

.breadcrumbs > ul > li > a, .breadcrumbs > ol > li > a {
  cursor: pointer;
  align-items: center;
  display: flex;
}

@media (hover: hover) {
  .breadcrumbs > ul > li > a:hover, .breadcrumbs > ol > li > a:hover, .link-hover:hover {
    text-decoration-line: underline;
  }

  .checkbox-primary:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--p) / var(--tw-border-opacity));
  }

  .checkbox-secondary:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--s) / var(--tw-border-opacity));
  }

  .checkbox-accent:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--a) / var(--tw-border-opacity));
  }

  .checkbox-success:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--su) / var(--tw-border-opacity));
  }

  .checkbox-warning:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--wa) / var(--tw-border-opacity));
  }

  .checkbox-info:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--in) / var(--tw-border-opacity));
  }

  .checkbox-error:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--er) / var(--tw-border-opacity));
  }

  .label a:hover {
    --tw-text-opacity: 1;
    color: hsl(var(--bc) / var(--tw-text-opacity));
  }

  .link-primary:hover {
    --tw-text-opacity: 1;
    color: hsl(var(--pf) / var(--tw-text-opacity));
  }

  .link-secondary:hover {
    --tw-text-opacity: 1;
    color: hsl(var(--sf) / var(--tw-text-opacity));
  }

  .link-accent:hover {
    --tw-text-opacity: 1;
    color: hsl(var(--af) / var(--tw-text-opacity));
  }

  .link-neutral:hover {
    --tw-text-opacity: 1;
    color: hsl(var(--nf) / var(--tw-text-opacity));
  }

  .link-success:hover {
    --tw-text-opacity: 1;
    color: hsl(var(--su) / var(--tw-text-opacity));
  }

  .link-info:hover {
    --tw-text-opacity: 1;
    color: hsl(var(--in) / var(--tw-text-opacity));
  }

  .link-warning:hover {
    --tw-text-opacity: 1;
    color: hsl(var(--wa) / var(--tw-text-opacity));
  }

  .link-error:hover {
    --tw-text-opacity: 1;
    color: hsl(var(--er) / var(--tw-text-opacity));
  }

  .menu li > :not(ul):not(.menu-title):not(details):active, .menu li > :not(ul):not(.menu-title):not(details).active, .menu li > details > summary:active {
    --tw-bg-opacity: 1;
    background-color: hsl(var(--n) / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: hsl(var(--nc) / var(--tw-text-opacity));
  }

  .radio-primary:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--p) / var(--tw-border-opacity));
  }

  .radio-secondary:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--s) / var(--tw-border-opacity));
  }

  .radio-accent:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--a) / var(--tw-border-opacity));
  }

  .radio-success:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--su) / var(--tw-border-opacity));
  }

  .radio-warning:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--wa) / var(--tw-border-opacity));
  }

  .radio-info:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--in) / var(--tw-border-opacity));
  }

  .radio-error:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--er) / var(--tw-border-opacity));
  }

  .tab:hover {
    --tw-text-opacity: 1;
  }

  .tabs-boxed .tab-active:not(.tab-disabled):not([disabled]):hover {
    --tw-text-opacity: 1;
    color: hsl(var(--pc) / var(--tw-text-opacity));
  }

  .table tr.hover:hover, .table tr.hover:nth-child(2n):hover {
    --tw-bg-opacity: 1;
    background-color: hsl(var(--b2) / var(--tw-bg-opacity));
  }

  .table-zebra tr.hover:hover, .table-zebra tr.hover:nth-child(2n):hover {
    --tw-bg-opacity: 1;
    background-color: hsl(var(--b3) / var(--tw-bg-opacity));
  }
}

.btn {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border-color: #0000;
  border-color: hsl(var(--b2) / var(--tw-border-opacity));
  text-align: center;
  border-radius: var(--rounded-btn, .5rem);
  border-width: var(--border-btn, 1px);
  animation: button-pop var(--animation-btn, .25s) ease-out;
  text-transform: var(--btn-text-case, uppercase);
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity));
  outline-color: hsl(var(--bc) / 1);
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  height: 3rem;
  min-height: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: .875rem;
  font-weight: 600;
  line-height: 1em;
  text-decoration-line: none;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
  display: inline-flex;
}

.btn-disabled, .btn[disabled], .btn:disabled {
  pointer-events: none;
}

.btn-square {
  width: 3rem;
  height: 3rem;
  padding: 0;
}

.btn-circle {
  border-radius: 9999px;
  width: 3rem;
  height: 3rem;
  padding: 0;
}

.btn-group {
  display: inline-flex;
}

.btn-group > input[type="radio"].btn {
  appearance: none;
}

.btn-group > input[type="radio"].btn:before {
  content: attr(data-title);
}

.btninput[type="checkbox"], .btninput[type="radio"] {
  appearance: none;
  width: auto;
}

.btninput[type="checkbox"]:after, .btninput[type="radio"]:after {
  --tw-content: attr(aria-label);
  content: var(--tw-content);
}

.card {
  border-radius: var(--rounded-box, 1rem);
  flex-direction: column;
  display: flex;
  position: relative;
}

.card:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.card-body {
  padding: var(--padding-card, 2rem);
  flex-direction: column;
  flex: auto;
  gap: .5rem;
  display: flex;
}

.card-body :where(p) {
  flex-grow: 1;
}

.card-actions {
  flex-wrap: wrap;
  align-items: flex-start;
  gap: .5rem;
  display: flex;
}

.card figure {
  justify-content: center;
  align-items: center;
  display: flex;
}

.card.image-full {
  display: grid;
}

.card.image-full:before {
  content: "";
  z-index: 10;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--n) / var(--tw-bg-opacity));
  opacity: .75;
  border-radius: var(--rounded-box, 1rem);
  position: relative;
}

.card.image-full:before, .card.image-full > * {
  grid-row-start: 1;
  grid-column-start: 1;
}

.card.image-full > figure img {
  object-fit: cover;
  height: 100%;
}

.card.image-full > .card-body {
  z-index: 20;
  --tw-text-opacity: 1;
  color: hsl(var(--nc) / var(--tw-text-opacity));
  position: relative;
}

.carousel {
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: inline-flex;
  overflow-x: scroll;
}

.carousel-vertical {
  scroll-snap-type: y mandatory;
  flex-direction: column;
  overflow-y: scroll;
}

.carousel-item {
  box-sizing: content-box;
  scroll-snap-align: start;
  flex: none;
  display: flex;
}

.carousel-center .carousel-item {
  scroll-snap-align: center;
}

.carousel-end .carousel-item {
  scroll-snap-align: end;
}

.chat {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: .75rem;
  padding-top: .25rem;
  padding-bottom: .25rem;
  display: grid;
}

.chat-image {
  grid-row: span 2 / span 2;
  align-self: flex-end;
}

.chat-header {
  grid-row-start: 1;
  font-size: .875rem;
  line-height: 1.25rem;
}

.chat-footer {
  grid-row-start: 3;
  font-size: .875rem;
  line-height: 1.25rem;
}

.chat-bubble {
  border-radius: var(--rounded-box, 1rem);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--n) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--nc) / var(--tw-text-opacity));
  width: -moz-fit-content;
  width: fit-content;
  min-width: 2.75rem;
  max-width: 90%;
  min-height: 2.75rem;
  padding: .5rem 1rem;
  display: block;
  position: relative;
}

.chat-bubble:before {
  background-color: inherit;
  content: "";
  width: .75rem;
  height: .75rem;
  position: absolute;
  bottom: 0;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.chat-start {
  grid-template-columns: auto 1fr;
  place-items: start;
}

.chat-start .chat-header, .chat-start .chat-footer {
  grid-column-start: 2;
}

.chat-start .chat-image {
  grid-column-start: 1;
}

.chat-start .chat-bubble {
  border-bottom-left-radius: 0;
  grid-column-start: 2;
}

.chat-start .chat-bubble:before {
  left: -.75rem;
  -webkit-mask-image: url("data:image/svg+xml,%3csvg width='3' height='3' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='black' d='m 0 3 L 3 3 L 3 0 C 3 1 1 3 0 3'/%3e%3c/svg%3e");
  mask-image: url("data:image/svg+xml,%3csvg width='3' height='3' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='black' d='m 0 3 L 3 3 L 3 0 C 3 1 1 3 0 3'/%3e%3c/svg%3e");
}

[dir="rtl"] .chat-start .chat-bubble:before {
  -webkit-mask-image: url("data:image/svg+xml,%3csvg width='3' height='3' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='black' d='m 0 3 L 1 3 L 3 3 C 2 3 0 1 0 0'/%3e%3c/svg%3e");
  mask-image: url("data:image/svg+xml,%3csvg width='3' height='3' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='black' d='m 0 3 L 1 3 L 3 3 C 2 3 0 1 0 0'/%3e%3c/svg%3e");
}

.chat-end {
  grid-template-columns: 1fr auto;
  place-items: end;
}

.chat-end .chat-header, .chat-end .chat-footer {
  grid-column-start: 1;
}

.chat-end .chat-image {
  grid-column-start: 2;
}

.chat-end .chat-bubble {
  border-bottom-right-radius: 0;
  grid-column-start: 1;
}

.chat-end .chat-bubble:before {
  left: 100%;
  -webkit-mask-image: url("data:image/svg+xml,%3csvg width='3' height='3' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='black' d='m 0 3 L 1 3 L 3 3 C 2 3 0 1 0 0'/%3e%3c/svg%3e");
  mask-image: url("data:image/svg+xml,%3csvg width='3' height='3' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='black' d='m 0 3 L 1 3 L 3 3 C 2 3 0 1 0 0'/%3e%3c/svg%3e");
}

[dir="rtl"] .chat-end .chat-bubble:before {
  -webkit-mask-image: url("data:image/svg+xml,%3csvg width='3' height='3' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='black' d='m 0 3 L 3 3 L 3 0 C 3 1 1 3 0 3'/%3e%3c/svg%3e");
  mask-image: url("data:image/svg+xml,%3csvg width='3' height='3' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='black' d='m 0 3 L 3 3 L 3 0 C 3 1 1 3 0 3'/%3e%3c/svg%3e");
}

.\!checkbox {
  --chkbg: var(--bc) !important;
  --chkfg: var(--b1) !important;
  cursor: pointer !important;
  appearance: none !important;
  border-width: 1px !important;
  border-color: hsl(var(--bc) / var(--tw-border-opacity)) !important;
  --tw-border-opacity: .2 !important;
  border-radius: var(--rounded-btn, .5rem) !important;
  flex-shrink: 0 !important;
  width: 1.5rem !important;
  height: 1.5rem !important;
}

.checkbox {
  --chkbg: var(--bc);
  --chkfg: var(--b1);
  cursor: pointer;
  appearance: none;
  border-width: 1px;
  border-color: hsl(var(--bc) / var(--tw-border-opacity));
  --tw-border-opacity: .2;
  border-radius: var(--rounded-btn, .5rem);
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
}

.collapse:not(td):not(tr):not(colgroup) {
  visibility: visible;
}

.collapse {
  border-radius: var(--rounded-box, 1rem);
  grid-template-rows: auto 0fr;
  width: 100%;
  transition: grid-template-rows .2s;
  display: grid;
  position: relative;
  overflow: hidden;
}

.collapse-title, .collapse > input[type="checkbox"], .collapse > input[type="radio"], .collapse-content {
  grid-row-start: 1;
  grid-column-start: 1;
}

.collapse > input[type="checkbox"], .collapse > input[type="radio"] {
  appearance: none;
  opacity: 0;
}

.collapse-content {
  visibility: hidden;
  cursor: unset;
  grid-row-start: 2;
  grid-column-start: 1;
  min-height: 0;
  padding-left: 1rem;
  padding-right: 1rem;
  transition: padding .2s ease-out, background-color .2s ease-out;
}

.collapse[open], .collapse-open, .collapse:focus:not(.collapse-close), :is(.collapse:not(.collapse-close):has( > input[type="checkbox"]:checked), .collapse:not(.collapse-close):has( > input[type="radio"]:checked)) {
  grid-template-rows: auto 1fr;
}

.collapse[open] .collapse-content, .collapse-open .collapse-content, .collapse:focus:not(.collapse-close) .collapse-content, .collapse:not(.collapse-close) input[type="checkbox"]:checked ~ .collapse-content, .collapse:not(.collapse-close) input[type="radio"]:checked ~ .collapse-content {
  visibility: visible;
  min-height: -moz-fit-content;
  min-height: fit-content;
}

:root .countdown {
  line-height: 1em;
}

.countdown {
  display: inline-flex;
}

.countdown > * {
  height: 1em;
  display: inline-block;
  overflow-y: hidden;
}

.countdown > :before {
  content: "00\a 01\a 02\a 03\a 04\a 05\a 06\a 07\a 08\a 09\a 10\a 11\a 12\a 13\a 14\a 15\a 16\a 17\a 18\a 19\a 20\a 21\a 22\a 23\a 24\a 25\a 26\a 27\a 28\a 29\a 30\a 31\a 32\a 33\a 34\a 35\a 36\a 37\a 38\a 39\a 40\a 41\a 42\a 43\a 44\a 45\a 46\a 47\a 48\a 49\a 50\a 51\a 52\a 53\a 54\a 55\a 56\a 57\a 58\a 59\a 60\a 61\a 62\a 63\a 64\a 65\a 66\a 67\a 68\a 69\a 70\a 71\a 72\a 73\a 74\a 75\a 76\a 77\a 78\a 79\a 80\a 81\a 82\a 83\a 84\a 85\a 86\a 87\a 88\a 89\a 90\a 91\a 92\a 93\a 94\a 95\a 96\a 97\a 98\a 99\a ";
  white-space: pre;
  top: calc(var(--value) * -1em);
  text-align: center;
  transition: all 1s cubic-bezier(1, 0, 0, 1);
  position: relative;
}

.divider {
  white-space: nowrap;
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  height: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
}

.divider:before, .divider:after {
  content: "";
  flex-grow: 1;
  width: 100%;
  height: .125rem;
}

.drawer {
  grid-auto-columns: max-content auto;
  width: 100%;
  display: grid;
  position: relative;
}

.drawer-content {
  grid-row-start: 1;
  grid-column-start: 2;
}

.drawer-side {
  pointer-events: none;
  overscroll-behavior: contain;
  scrollbar-width: none;
  grid-template-rows: repeat(1, minmax(0, 1fr));
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-row-start: 1;
  grid-column-start: 1;
  place-items: flex-start start;
  width: 100%;
  height: 100dvh;
  display: grid;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
}

.drawer-side::-webkit-scrollbar {
  display: none;
}

.drawer-side > .drawer-overlay {
  cursor: pointer;
  background-color: #0000;
  place-self: stretch stretch;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
  position: sticky;
  top: 0;
}

.drawer-side > * {
  grid-row-start: 1;
  grid-column-start: 1;
}

.drawer-side > :not(.drawer-overlay) {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
  transform: translateX(-100%);
}

[dir="rtl"] .drawer-side > :not(.drawer-overlay) {
  transform: translateX(100%);
}

.drawer-toggle {
  appearance: none;
  opacity: 0;
  width: 0;
  height: 0;
  position: fixed;
}

.drawer-toggle:checked ~ .drawer-side {
  pointer-events: auto;
  visibility: visible;
}

.drawer-toggle:checked ~ .drawer-side > :not(.drawer-overlay) {
  transform: translateX(0%);
}

.drawer-end {
  grid-auto-columns: auto max-content;
}

.drawer-end .drawer-toggle ~ .drawer-content {
  grid-column-start: 1;
}

.drawer-end .drawer-toggle ~ .drawer-side {
  justify-items: end;
}

.drawer-end .drawer-toggle ~ .drawer-side > :not(.drawer-overlay) {
  transform: translateX(100%);
}

[dir="rtl"] .drawer-end .drawer-toggle ~ .drawer-side > :not(.drawer-overlay) {
  transform: translateX(-100%);
}

.drawer-end .drawer-toggle:checked ~ .drawer-side > :not(.drawer-overlay) {
  transform: translateX(0%);
}

.dropdown {
  display: inline-block;
  position: relative;
}

.dropdown > :focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.dropdown .dropdown-content {
  position: absolute;
}

.dropdown:not(details) .dropdown-content {
  visibility: hidden;
  opacity: 0;
  transform-origin: top;
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.dropdown-end .dropdown-content {
  right: 0;
}

.dropdown-left .dropdown-content {
  transform-origin: 100%;
  top: 0;
  bottom: auto;
  right: 100%;
}

.dropdown-right .dropdown-content {
  transform-origin: 0;
  top: 0;
  bottom: auto;
  left: 100%;
}

.dropdown-bottom .dropdown-content {
  transform-origin: top;
  top: 100%;
  bottom: auto;
}

.dropdown-top .dropdown-content {
  transform-origin: bottom;
  top: auto;
  bottom: 100%;
}

.dropdown-end.dropdown-right .dropdown-content, .dropdown-end.dropdown-left .dropdown-content {
  top: auto;
  bottom: 0;
}

.dropdown.dropdown-open .dropdown-content, .dropdown:not(.dropdown-hover):focus .dropdown-content, .dropdown:focus-within .dropdown-content {
  visibility: visible;
  opacity: 1;
}

@media (hover: hover) {
  .dropdown.dropdown-hover:hover .dropdown-content {
    visibility: visible;
    opacity: 1;
  }

  .btm-nav > .disabled:hover, .btm-nav > [disabled]:hover {
    pointer-events: none;
    --tw-border-opacity: 0;
    background-color: hsl(var(--n) / var(--tw-bg-opacity));
    --tw-bg-opacity: .1;
    color: hsl(var(--bc) / var(--tw-text-opacity));
    --tw-text-opacity: .2;
  }

  .btn:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--b3) / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: hsl(var(--b3) / var(--tw-bg-opacity));
  }

  .btn-primary:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--pf) / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: hsl(var(--pf) / var(--tw-bg-opacity));
  }

  .btn-secondary:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--sf) / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: hsl(var(--sf) / var(--tw-bg-opacity));
  }

  .btn-accent:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--af) / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: hsl(var(--af) / var(--tw-bg-opacity));
  }

  .btn-neutral:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--nf) / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: hsl(var(--nf) / var(--tw-bg-opacity));
  }

  .btn-info:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--in) / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: hsl(var(--in) / var(--tw-bg-opacity));
  }

  .btn-success:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--su) / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: hsl(var(--su) / var(--tw-bg-opacity));
  }

  .btn-warning:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--wa) / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: hsl(var(--wa) / var(--tw-bg-opacity));
  }

  .btn-error:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--er) / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: hsl(var(--er) / var(--tw-bg-opacity));
  }

  .btn.glass:hover {
    --glass-opacity: 25%;
    --glass-border-opacity: 15%;
  }

  .btn-ghost:hover {
    --tw-border-opacity: 0;
    background-color: hsl(var(--bc) / var(--tw-bg-opacity));
    --tw-bg-opacity: .2;
  }

  .btn-link:hover {
    background-color: #0000;
    border-color: #0000;
    text-decoration-line: underline;
  }

  .btn-outline:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--bc) / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: hsl(var(--bc) / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: hsl(var(--b1) / var(--tw-text-opacity));
  }

  .btn-outline.btn-primary:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--pf) / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: hsl(var(--pf) / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: hsl(var(--pc) / var(--tw-text-opacity));
  }

  .btn-outline.btn-secondary:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--sf) / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: hsl(var(--sf) / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: hsl(var(--sc) / var(--tw-text-opacity));
  }

  .btn-outline.btn-accent:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--af) / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: hsl(var(--af) / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: hsl(var(--ac) / var(--tw-text-opacity));
  }

  .btn-outline.btn-success:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--su) / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: hsl(var(--su) / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: hsl(var(--suc) / var(--tw-text-opacity));
  }

  .btn-outline.btn-info:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--in) / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: hsl(var(--in) / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: hsl(var(--inc) / var(--tw-text-opacity));
  }

  .btn-outline.btn-warning:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--wa) / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: hsl(var(--wa) / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: hsl(var(--wac) / var(--tw-text-opacity));
  }

  .btn-outline.btn-error:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--er) / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: hsl(var(--er) / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: hsl(var(--erc) / var(--tw-text-opacity));
  }

  .btn-disabled:hover, .btn[disabled]:hover, .btn:disabled:hover {
    --tw-border-opacity: 0;
    background-color: hsl(var(--n) / var(--tw-bg-opacity));
    --tw-bg-opacity: .2;
    color: hsl(var(--bc) / var(--tw-text-opacity));
    --tw-text-opacity: .2;
  }

  .btninput[type="checkbox"]:checked:hover, .btninput[type="radio"]:checked:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--pf) / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: hsl(var(--pf) / var(--tw-bg-opacity));
  }

  .dropdown.dropdown-hover:hover .dropdown-content {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  :where(.menu li:not(.menu-title):not(.disabled) > :not(ul):not(details):not(.menu-title)):not(.active):hover, :where(.menu li:not(.menu-title):not(.disabled) > details > summary:not(.menu-title)):not(.active):hover {
    cursor: pointer;
    background-color: hsl(var(--bc) / .1);
    --tw-text-opacity: 1;
    color: hsl(var(--bc) / var(--tw-text-opacity));
    outline-offset: 2px;
    outline: 2px solid #0000;
  }

  .tab[disabled], .tab[disabled]:hover {
    cursor: not-allowed;
    color: hsl(var(--bc) / var(--tw-text-opacity));
    --tw-text-opacity: .2;
  }
}

.dropdown:is(details) summary::-webkit-details-marker {
  display: none;
}

.file-input {
  border-width: 1px;
  border-color: hsl(var(--bc) / var(--tw-border-opacity));
  --tw-border-opacity: 0;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b1) / var(--tw-bg-opacity));
  border-radius: var(--rounded-btn, .5rem);
  flex-shrink: 1;
  height: 3rem;
  padding-right: 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
  overflow: hidden;
}

.file-input::file-selector-button {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  text-align: center;
  --tw-border-opacity: 1;
  border-style: solid;
  border-color: hsl(var(--n) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--n) / var(--tw-bg-opacity));
  text-transform: uppercase;
  text-transform: var(--btn-text-case, uppercase);
  --tw-text-opacity: 1;
  color: hsl(var(--nc) / var(--tw-text-opacity));
  border-width: var(--border-btn, 1px);
  animation: button-pop var(--animation-btn, .25s) ease-out;
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  height: 2.875rem;
  min-height: 2.875rem;
  margin-right: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: .875rem;
  font-weight: 600;
  line-height: 1em;
  text-decoration-line: none;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
  display: inline-flex;
}

.footer {
  grid-auto-flow: row;
  place-items: start;
  gap: 2.5rem 1rem;
  width: 100%;
  font-size: .875rem;
  line-height: 1.25rem;
  display: grid;
}

.footer > * {
  place-items: start;
  gap: .5rem;
  display: grid;
}

.footer-center {
  text-align: center;
  place-items: center;
}

.footer-center > * {
  place-items: center;
}

@media (width >= 48rem) {
  .footer {
    grid-auto-flow: column;
  }

  .footer-center {
    grid-auto-flow: row dense;
  }
}

.form-control {
  flex-direction: column;
  display: flex;
}

.label {
  -webkit-user-select: none;
  user-select: none;
  justify-content: space-between;
  align-items: center;
  padding: .5rem .25rem;
  display: flex;
}

.hero {
  background-position: center;
  background-size: cover;
  place-items: center;
  width: 100%;
  display: grid;
}

.hero > * {
  grid-row-start: 1;
  grid-column-start: 1;
}

.hero-overlay {
  background-color: hsl(var(--n) / var(--tw-bg-opacity));
  --tw-bg-opacity: .5;
  grid-row-start: 1;
  grid-column-start: 1;
  width: 100%;
  height: 100%;
}

.hero-content {
  z-index: 0;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  max-width: 80rem;
  padding: 1rem;
  display: flex;
}

.indicator {
  width: max-content;
  display: inline-flex;
  position: relative;
}

.indicator :where(.indicator-item) {
  z-index: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  white-space: nowrap;
  position: absolute;
}

.input {
  border-width: 1px;
  border-color: hsl(var(--bc) / var(--tw-border-opacity));
  --tw-border-opacity: 0;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b1) / var(--tw-bg-opacity));
  border-radius: var(--rounded-btn, .5rem);
  flex-shrink: 1;
  height: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

.input-group {
  align-items: stretch;
  width: 100%;
  display: flex;
}

.input-group > .input {
  isolation: isolate;
}

.input-group > *, .input-group > .input, .input-group > .textarea, .input-group > .select {
  border-radius: 0;
}

.input-group-md {
  font-size: .875rem;
  line-height: 2;
}

.input-group-lg {
  font-size: 1.125rem;
  line-height: 2;
}

.input-group-sm {
  font-size: .875rem;
  line-height: 2rem;
}

.input-group-xs {
  font-size: .75rem;
  line-height: 1.625;
}

.input-group :where(span) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b3) / var(--tw-bg-opacity));
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
}

.input-group > :first-child {
  border-top-left-radius: var(--rounded-btn, .5rem);
  border-top-right-radius: 0;
  border-bottom-left-radius: var(--rounded-btn, .5rem);
  border-bottom-right-radius: 0;
}

.input-group > :last-child {
  border-top-left-radius: 0;
  border-top-right-radius: var(--rounded-btn, .5rem);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: var(--rounded-btn, .5rem);
}

.input-group-vertical {
  flex-direction: column;
}

.input-group-vertical :first-child {
  border-top-left-radius: var(--rounded-btn, .5rem);
  border-top-right-radius: var(--rounded-btn, .5rem);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-vertical :last-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: var(--rounded-btn, .5rem);
  border-bottom-right-radius: var(--rounded-btn, .5rem);
}

.join {
  border-radius: var(--rounded-btn, .5rem);
  align-items: stretch;
  display: inline-flex;
}

.join :where(.join-item), .join .join-item:not(:first-child):not(:last-child), .join :not(:first-child):not(:last-child) .join-item {
  border-radius: 0;
}

.join .join-item:first-child:not(:last-child), .join :first-child:not(:last-child) .join-item {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.join :where(.join-item:first-child:not(:last-child)), .join :where(:first-child:not(:last-child) .join-item) {
  border-bottom-left-radius: inherit;
  border-top-left-radius: inherit;
}

.join .join-item:last-child:not(:first-child), .join :last-child:not(:first-child) .join-item {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.join :where(.join-item:last-child:not(:first-child)), .join :where(:last-child:not(:first-child) .join-item) {
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
}

:where(.join *) {
  border-radius: inherit;
}

.kbd {
  border-width: 1px;
  border-color: hsl(var(--bc) / var(--tw-border-opacity));
  --tw-border-opacity: .2;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2) / var(--tw-bg-opacity));
  border-radius: var(--rounded-btn, .5rem);
  border-bottom-width: 2px;
  justify-content: center;
  align-items: center;
  min-width: 2.2em;
  min-height: 2.2em;
  padding-left: .5rem;
  padding-right: .5rem;
  display: inline-flex;
}

.link {
  cursor: pointer;
  text-decoration-line: underline;
}

.link-hover {
  text-decoration-line: none;
}

.mask {
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.mask-half-1 {
  -webkit-mask-position: 0;
  mask-position: 0;
  -webkit-mask-size: 200%;
  mask-size: 200%;
}

.mask-half-2 {
  -webkit-mask-position: 100%;
  mask-position: 100%;
  -webkit-mask-size: 200%;
  mask-size: 200%;
}

.menu {
  flex-flow: column wrap;
  padding: .5rem;
  font-size: .875rem;
  line-height: 1.25rem;
  display: flex;
}

.menu :where(li ul) {
  white-space: nowrap;
  margin-left: 1rem;
  padding-left: .5rem;
  position: relative;
}

.menu :where(li:not(.menu-title) > :not(ul):not(details):not(.menu-title)), .menu :where(li:not(.menu-title) > details > summary:not(.menu-title)) {
  -webkit-user-select: none;
  user-select: none;
  grid-auto-columns: minmax(auto, max-content) auto max-content;
  grid-auto-flow: column;
  align-content: flex-start;
  align-items: center;
  gap: .5rem;
  display: grid;
}

.menu li.disabled {
  cursor: not-allowed;
  -webkit-user-select: none;
  user-select: none;
  color: hsl(var(--bc) / .3);
}

.menu :where(li > .menu-dropdown:not(.menu-dropdown-show)) {
  display: none;
}

:where(.menu li) {
  flex-flow: column wrap;
  flex-shrink: 0;
  align-items: stretch;
  display: flex;
  position: relative;
}

:where(.menu li) .badge {
  justify-self: end;
}

.mockup-code {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--n) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--nc) / var(--tw-text-opacity));
  border-radius: var(--rounded-box, 1rem);
  min-width: 18rem;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
}

.mockup-code pre[data-prefix]:before {
  content: attr(data-prefix);
  text-align: right;
  opacity: .5;
  width: 2rem;
  display: inline-block;
}

.mockup-window {
  border-radius: var(--rounded-box, 1rem);
  padding-top: 1.25rem;
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
}

.mockup-window pre[data-prefix]:before {
  content: attr(data-prefix);
  text-align: right;
  display: inline-block;
}

.mockup-browser {
  border-radius: var(--rounded-box, 1rem);
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
}

.mockup-browser pre[data-prefix]:before {
  content: attr(data-prefix);
  text-align: right;
  display: inline-block;
}

.modal {
  pointer-events: none;
  opacity: 0;
  overscroll-behavior: contain;
  z-index: 999;
  color: inherit;
  background-color: #0000;
  justify-items: center;
  width: 100%;
  max-width: none;
  height: 100%;
  max-height: none;
  margin: 0;
  padding: 0;
  transition-property: transform, opacity, visibility;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
  display: grid;
  position: fixed;
  inset: 0;
  overflow-y: hidden;
}

.modal-scroll {
  overscroll-behavior: auto;
}

:where(.modal) {
  align-items: center;
}

.modal-box {
  --tw-scale-x: .9;
  --tw-scale-y: .9;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b1) / var(--tw-bg-opacity));
  border-top-left-radius: var(--rounded-box, 1rem);
  border-top-right-radius: var(--rounded-box, 1rem);
  border-bottom-left-radius: var(--rounded-box, 1rem);
  border-bottom-right-radius: var(--rounded-box, 1rem);
  overscroll-behavior: contain;
  grid-row-start: 1;
  grid-column-start: 1;
  width: 91.6667%;
  max-width: 32rem;
  max-height: calc(100vh - 5em);
  padding: 1.5rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
  overflow-y: auto;
  box-shadow: 0 25px 50px -12px #00000040;
}

.modal-open, .modal:target, .modal-toggle:checked + .modal, .modal[open] {
  pointer-events: auto;
  visibility: visible;
  opacity: 1;
}

.modal-action {
  justify-content: flex-end;
  margin-top: 1.5rem;
  display: flex;
}

.modal-toggle {
  appearance: none;
  opacity: 0;
  width: 0;
  height: 0;
  position: fixed;
}

:root:has(:is(.modal-open, .modal:target, .modal-toggle:checked + .modal, .modal[open])) {
  overflow: hidden;
}

.navbar {
  padding: var(--navbar-padding, .5rem);
  align-items: center;
  width: 100%;
  min-height: 4rem;
  display: flex;
}

:where(.navbar > *) {
  align-items: center;
  display: inline-flex;
}

.navbar-start {
  justify-content: flex-start;
  width: 50%;
}

.navbar-center {
  flex-shrink: 0;
}

.navbar-end {
  justify-content: flex-end;
  width: 50%;
}

.progress {
  appearance: none;
  background-color: hsl(var(--bc) / .2);
  border-radius: var(--rounded-box, 1rem);
  width: 100%;
  height: .5rem;
  position: relative;
  overflow: hidden;
}

.radial-progress {
  height: var(--size);
  width: var(--size);
  vertical-align: middle;
  box-sizing: content-box;
  --value: 0;
  --size: 5rem;
  --thickness: calc(var(--size) / 10);
  background-color: #0000;
  border-radius: 9999px;
  place-content: center;
  display: inline-grid;
  position: relative;
}

.radial-progress::-moz-progress-bar {
  appearance: none;
  background-color: #0000;
}

.radial-progress::-webkit-progress-value {
  appearance: none;
  background-color: #0000;
}

.radial-progress::-webkit-progress-bar {
  appearance: none;
  background-color: #0000;
}

.radial-progress:before, .radial-progress:after {
  content: "";
  border-radius: 9999px;
  position: absolute;
}

.radial-progress:before {
  background: radial-gradient(farthest-side, currentColor 98%, #0000) top / var(--thickness) var(--thickness) no-repeat, conic-gradient(currentColor calc(var(--value) * 1%), #0000 0);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(99% - var(--thickness)), #000 calc(100% - var(--thickness)));
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(99% - var(--thickness)), #000 calc(100% - var(--thickness)));
  mask: radial-gradient(farthest-side, #0000 calc(99% - var(--thickness)), #000 calc(100% - var(--thickness)));
  inset: 0;
}

.radial-progress:after {
  inset: calc(50% - var(--thickness) / 2);
  transform: rotate(calc(var(--value) * 3.6deg - 90deg)) translate(calc(var(--size) / 2 - 50%));
  background-color: currentColor;
}

.radio {
  --chkbg: var(--bc);
  cursor: pointer;
  appearance: none;
  border-width: 1px;
  border-color: hsl(var(--bc) / var(--tw-border-opacity));
  --tw-border-opacity: .2;
  border-radius: 9999px;
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
}

.range {
  cursor: pointer;
  appearance: none;
  --range-shdw: var(--bc);
  border-radius: var(--rounded-box, 1rem);
  background-color: #0000;
  width: 100%;
  height: 1.5rem;
  overflow: hidden;
}

.range:focus {
  outline: none;
}

.rating {
  display: inline-flex;
  position: relative;
}

.rating :where(input) {
  cursor: pointer;
  animation: rating-pop var(--animation-input, .25s) ease-out;
  background-color: hsl(var(--bc) / var(--tw-bg-opacity));
  --tw-bg-opacity: 1;
  border-radius: 0;
  width: 1.5rem;
  height: 1.5rem;
}

.select {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  appearance: none;
  border-width: 1px;
  border-color: hsl(var(--bc) / var(--tw-border-opacity));
  --tw-border-opacity: 0;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b1) / var(--tw-bg-opacity));
  border-radius: var(--rounded-btn, .5rem);
  background-image: linear-gradient(45deg, #0000 50%, currentColor 50%), linear-gradient(135deg, currentColor 50%, #0000 50%);
  background-position: calc(100% - 20px) calc(1px + 50%), calc(100% - 16px) calc(1px + 50%);
  background-repeat: no-repeat;
  background-size: 4px 4px, 4px 4px;
  height: 3rem;
  min-height: 3rem;
  padding-left: 1rem;
  padding-right: 2.5rem;
  font-size: .875rem;
  font-weight: 600;
  line-height: 2;
  display: inline-flex;
}

.select[multiple] {
  height: auto;
}

.stack {
  place-items: flex-end center;
  display: inline-grid;
}

.stack > * {
  z-index: 1;
  opacity: .6;
  grid-row-start: 1;
  grid-column-start: 1;
  width: 100%;
  transform: translateY(10%)scale(.9);
}

.stack > :nth-child(2) {
  z-index: 2;
  opacity: .8;
  transform: translateY(5%)scale(.95);
}

.stack > :first-child {
  z-index: 3;
  opacity: 1;
  transform: translateY(0)scale(1);
}

.stats {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b1) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity));
  border-radius: var(--rounded-box, 1rem);
  display: inline-grid;
}

:where(.stats) {
  grid-auto-flow: column;
  overflow-x: auto;
}

.stat {
  border-color: hsl(var(--bc) / var(--tw-border-opacity));
  --tw-border-opacity: .1;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 1rem;
  width: 100%;
  padding: 1rem 1.5rem;
  display: inline-grid;
}

.stat-figure {
  grid-row: 1 / span 3;
  grid-column-start: 2;
  place-self: center end;
}

.stat-title {
  white-space: nowrap;
  color: hsl(var(--bc) / .6);
  grid-column-start: 1;
}

.stat-value {
  white-space: nowrap;
  grid-column-start: 1;
  font-size: 2.25rem;
  font-weight: 800;
  line-height: 2.5rem;
}

.stat-desc {
  white-space: nowrap;
  color: hsl(var(--bc) / .6);
  grid-column-start: 1;
  font-size: .75rem;
  line-height: 1rem;
}

.stat-actions {
  white-space: nowrap;
  grid-column-start: 1;
  margin-top: 1rem;
}

.steps {
  counter-reset: step;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  display: inline-grid;
  overflow-x: auto;
  overflow-y: hidden;
}

.steps .step {
  text-align: center;
  grid-template-rows: 40px 1fr;
  grid-template-columns: auto;
  place-items: center;
  min-width: 4rem;
  display: grid;
}

.swap {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  place-content: center;
  display: inline-grid;
  position: relative;
}

.swap > * {
  grid-row-start: 1;
  grid-column-start: 1;
  transition-property: transform, opacity;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.swap input {
  appearance: none;
}

.swap .swap-on, .swap .swap-indeterminate, .swap input:indeterminate ~ .swap-on, .swap input:checked ~ .swap-off, .swap.swap-active .swap-off, .swap input:indeterminate ~ .swap-off {
  opacity: 0;
}

.swap input:checked ~ .swap-on, .swap-active .swap-on, .swap input:indeterminate ~ .swap-indeterminate {
  opacity: 1;
}

.tabs {
  flex-wrap: wrap;
  align-items: flex-end;
  display: flex;
}

.tab {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  text-align: center;
  --tab-padding: 1rem;
  --tw-text-opacity: .5;
  --tab-color: hsl(var(--bc) / var(--tw-text-opacity, 1));
  --tab-bg: hsl(var(--b1) / var(--tw-bg-opacity, 1));
  --tab-border-color: hsl(var(--b3) / var(--tw-bg-opacity, 1));
  color: var(--tab-color);
  padding-left: var(--tab-padding, 1rem);
  padding-right: var(--tab-padding, 1rem);
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 2rem;
  font-size: .875rem;
  line-height: 2;
  display: inline-flex;
  position: relative;
}

.table {
  text-align: left;
  border-radius: var(--rounded-box, 1rem);
  width: 100%;
  font-size: .875rem;
  line-height: 1.25rem;
  position: relative;
}

.table :where(.table-pin-rows thead tr) {
  z-index: 1;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b1) / var(--tw-bg-opacity));
  position: sticky;
  top: 0;
}

.table :where(.table-pin-rows tfoot tr) {
  z-index: 1;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b1) / var(--tw-bg-opacity));
  position: sticky;
  bottom: 0;
}

.table :where(.table-pin-cols tr th) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b1) / var(--tw-bg-opacity));
  position: sticky;
  left: 0;
  right: 0;
}

.table-zebra tbody tr:nth-child(2n) :where(.table-pin-cols tr th) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2) / var(--tw-bg-opacity));
}

.textarea {
  border-width: 1px;
  border-color: hsl(var(--bc) / var(--tw-border-opacity));
  --tw-border-opacity: 0;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b1) / var(--tw-bg-opacity));
  border-radius: var(--rounded-btn, .5rem);
  flex-shrink: 1;
  min-height: 3rem;
  padding: .5rem 1rem;
  font-size: .875rem;
  line-height: 2;
}

.toast {
  white-space: nowrap;
  flex-direction: column;
  gap: .5rem;
  min-width: -moz-fit-content;
  min-width: fit-content;
  padding: 1rem;
  display: flex;
  position: fixed;
}

.toggle {
  --tglbg: hsl(var(--b1));
  --handleoffset: 1.5rem;
  --handleoffsetcalculator: calc(var(--handleoffset) * -1);
  --togglehandleborder: 0 0;
  cursor: pointer;
  appearance: none;
  border-width: 1px;
  border-color: hsl(var(--bc) / var(--tw-border-opacity));
  --tw-border-opacity: .2;
  background-color: hsl(var(--bc) / var(--tw-bg-opacity));
  --tw-bg-opacity: .5;
  border-radius: var(--rounded-badge, 1.9rem);
  transition: background, box-shadow var(--animation-input, .2s) ease-out;
  box-shadow: var(--handleoffsetcalculator) 0 0 2px var(--tglbg) inset, 0 0 0 2px var(--tglbg) inset, var(--togglehandleborder);
  flex-shrink: 0;
  width: 3rem;
  height: 1.5rem;
}

.alert-info {
  border-color: hsl(var(--in) / .2);
  --tw-text-opacity: 1;
  color: hsl(var(--inc) / var(--tw-text-opacity));
  --alert-bg: hsl(var(--in));
  --alert-bg-mix: hsl(var(--b1));
}

.alert-success {
  border-color: hsl(var(--su) / .2);
  --tw-text-opacity: 1;
  color: hsl(var(--suc) / var(--tw-text-opacity));
  --alert-bg: hsl(var(--su));
  --alert-bg-mix: hsl(var(--b1));
}

.alert-warning {
  border-color: hsl(var(--wa) / .2);
  --tw-text-opacity: 1;
  color: hsl(var(--wac) / var(--tw-text-opacity));
  --alert-bg: hsl(var(--wa));
  --alert-bg-mix: hsl(var(--b1));
}

.alert-error {
  border-color: hsl(var(--er) / .2);
  --tw-text-opacity: 1;
  color: hsl(var(--erc) / var(--tw-text-opacity));
  --alert-bg: hsl(var(--er));
  --alert-bg-mix: hsl(var(--b1));
}

.avatar-group {
  display: flex;
  overflow: hidden;
}

.avatar-group :where(.avatar) {
  --tw-border-opacity: 1;
  border-width: 4px;
  border-color: hsl(var(--b1) / var(--tw-border-opacity));
  border-radius: 9999px;
  overflow: hidden;
}

.badge-neutral {
  --tw-border-opacity: 1;
  border-color: hsl(var(--n) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--n) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--nc) / var(--tw-text-opacity));
}

.badge-primary {
  --tw-border-opacity: 1;
  border-color: hsl(var(--p) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--p) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--pc) / var(--tw-text-opacity));
}

.badge-secondary {
  --tw-border-opacity: 1;
  border-color: hsl(var(--s) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--s) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--sc) / var(--tw-text-opacity));
}

.badge-accent {
  --tw-border-opacity: 1;
  border-color: hsl(var(--a) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--a) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--ac) / var(--tw-text-opacity));
}

.badge-info {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--in) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--inc) / var(--tw-text-opacity));
  border-color: #0000;
}

.badge-success {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--su) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--suc) / var(--tw-text-opacity));
  border-color: #0000;
}

.badge-warning {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--wa) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--wac) / var(--tw-text-opacity));
  border-color: #0000;
}

.badge-error {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--er) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--erc) / var(--tw-text-opacity));
  border-color: #0000;
}

.badge-ghost {
  --tw-border-opacity: 1;
  border-color: hsl(var(--b2) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity));
}

.badge-outline {
  --tw-border-opacity: .5;
  color: currentColor;
  background-color: #0000;
  border-color: currentColor;
}

.badge-outline.badge-neutral {
  --tw-text-opacity: 1;
  color: hsl(var(--n) / var(--tw-text-opacity));
}

.badge-outline.badge-primary {
  --tw-text-opacity: 1;
  color: hsl(var(--p) / var(--tw-text-opacity));
}

.badge-outline.badge-secondary {
  --tw-text-opacity: 1;
  color: hsl(var(--s) / var(--tw-text-opacity));
}

.badge-outline.badge-accent {
  --tw-text-opacity: 1;
  color: hsl(var(--a) / var(--tw-text-opacity));
}

.badge-outline.badge-info {
  --tw-text-opacity: 1;
  color: hsl(var(--in) / var(--tw-text-opacity));
}

.badge-outline.badge-success {
  --tw-text-opacity: 1;
  color: hsl(var(--su) / var(--tw-text-opacity));
}

.badge-outline.badge-warning {
  --tw-text-opacity: 1;
  color: hsl(var(--wa) / var(--tw-text-opacity));
}

.badge-outline.badge-error {
  --tw-text-opacity: 1;
  color: hsl(var(--er) / var(--tw-text-opacity));
}

.btm-nav > :not(.active) {
  padding-top: .125rem;
}

.btm-nav > :where(.active) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b1) / var(--tw-bg-opacity));
  border-top-width: 2px;
}

.btm-nav > .disabled, .btm-nav > [disabled] {
  pointer-events: none;
  --tw-border-opacity: 0;
  background-color: hsl(var(--n) / var(--tw-bg-opacity));
  --tw-bg-opacity: .1;
  color: hsl(var(--bc) / var(--tw-text-opacity));
  --tw-text-opacity: .2;
}

.btm-nav > * .label {
  font-size: 1rem;
  line-height: 1.5rem;
}

.breadcrumbs > ul > li > a:focus, .breadcrumbs > ol > li > a:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.breadcrumbs > ul > li > a:focus-visible, .breadcrumbs > ol > li > a:focus-visible {
  outline-offset: 2px;
  outline: 2px solid;
}

.breadcrumbs > ul > li + :before, .breadcrumbs > ol > li + :before {
  content: "";
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  opacity: .4;
  background-color: #0000;
  border-top: 1px solid;
  border-right: 1px solid;
  width: .375rem;
  height: .375rem;
  margin-left: .5rem;
  margin-right: .75rem;
  display: block;
}

[dir="rtl"] .breadcrumbs > ul > li + :before, [dir="rtl"] .breadcrumbs > ol > li + :before {
  --tw-rotate: -45deg;
}

.btn:active:hover, .btn:active:focus {
  transform: scale(var(--btn-focus-scale, .97));
  animation: ease-out button-pop;
}

.btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--b3) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b3) / var(--tw-bg-opacity));
}

.btn:focus-visible {
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
}

.btn-primary {
  --tw-border-opacity: 1;
  border-color: hsl(var(--p) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--p) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--pc) / var(--tw-text-opacity));
  outline-color: hsl(var(--p) / 1);
}

.btn-primary.btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--pf) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--pf) / var(--tw-bg-opacity));
}

.btn-secondary {
  --tw-border-opacity: 1;
  border-color: hsl(var(--s) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--s) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--sc) / var(--tw-text-opacity));
  outline-color: hsl(var(--s) / 1);
}

.btn-secondary.btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--sf) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--sf) / var(--tw-bg-opacity));
}

.btn-accent {
  --tw-border-opacity: 1;
  border-color: hsl(var(--a) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--a) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--ac) / var(--tw-text-opacity));
  outline-color: hsl(var(--a) / 1);
}

.btn-accent.btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--af) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--af) / var(--tw-bg-opacity));
}

.btn-neutral {
  --tw-border-opacity: 1;
  border-color: hsl(var(--n) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--n) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--nc) / var(--tw-text-opacity));
  outline-color: hsl(var(--n) / 1);
}

.btn-neutral.btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nf) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nf) / var(--tw-bg-opacity));
}

.btn-info {
  --tw-border-opacity: 1;
  border-color: hsl(var(--in) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--in) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--inc) / var(--tw-text-opacity));
  outline-color: hsl(var(--in) / 1);
}

.btn-info.btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--in) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--in) / var(--tw-bg-opacity));
}

.btn-success {
  --tw-border-opacity: 1;
  border-color: hsl(var(--su) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--su) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--suc) / var(--tw-text-opacity));
  outline-color: hsl(var(--su) / 1);
}

.btn-success.btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--su) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--su) / var(--tw-bg-opacity));
}

.btn-warning {
  --tw-border-opacity: 1;
  border-color: hsl(var(--wa) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--wa) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--wac) / var(--tw-text-opacity));
  outline-color: hsl(var(--wa) / 1);
}

.btn-warning.btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--wa) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--wa) / var(--tw-bg-opacity));
}

.btn-error {
  --tw-border-opacity: 1;
  border-color: hsl(var(--er) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--er) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--erc) / var(--tw-text-opacity));
  outline-color: hsl(var(--er) / 1);
}

.btn-error.btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--er) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--er) / var(--tw-bg-opacity));
}

.btn.glass {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  outline-color: currentColor;
}

.btn.glass.btn-active {
  --glass-opacity: 25%;
  --glass-border-opacity: 15%;
}

.btn-ghost {
  color: currentColor;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  background-color: #0000;
  border-width: 1px;
  border-color: #0000;
  outline-color: currentColor;
}

.btn-ghost.btn-active {
  --tw-border-opacity: 0;
  background-color: hsl(var(--bc) / var(--tw-bg-opacity));
  --tw-bg-opacity: .2;
}

.btn-link {
  --tw-text-opacity: 1;
  color: hsl(var(--p) / var(--tw-text-opacity));
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  background-color: #0000;
  border-color: #0000;
  outline-color: currentColor;
  text-decoration-line: underline;
}

.btn-link.btn-active {
  background-color: #0000;
  border-color: #0000;
  text-decoration-line: underline;
}

.btn-outline {
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity));
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  background-color: #0000;
  border-color: currentColor;
}

.btn-outline.btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--bc) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--bc) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--b1) / var(--tw-text-opacity));
}

.btn-outline.btn-primary {
  --tw-text-opacity: 1;
  color: hsl(var(--p) / var(--tw-text-opacity));
}

.btn-outline.btn-primary.btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--pf) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--pf) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--pc) / var(--tw-text-opacity));
}

.btn-outline.btn-secondary {
  --tw-text-opacity: 1;
  color: hsl(var(--s) / var(--tw-text-opacity));
}

.btn-outline.btn-secondary.btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--sf) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--sf) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--sc) / var(--tw-text-opacity));
}

.btn-outline.btn-accent {
  --tw-text-opacity: 1;
  color: hsl(var(--a) / var(--tw-text-opacity));
}

.btn-outline.btn-accent.btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--af) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--af) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--ac) / var(--tw-text-opacity));
}

.btn-outline.btn-success {
  --tw-text-opacity: 1;
  color: hsl(var(--su) / var(--tw-text-opacity));
}

.btn-outline.btn-success.btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--su) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--su) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--suc) / var(--tw-text-opacity));
}

.btn-outline.btn-info {
  --tw-text-opacity: 1;
  color: hsl(var(--in) / var(--tw-text-opacity));
}

.btn-outline.btn-info.btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--in) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--in) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--inc) / var(--tw-text-opacity));
}

.btn-outline.btn-warning {
  --tw-text-opacity: 1;
  color: hsl(var(--wa) / var(--tw-text-opacity));
}

.btn-outline.btn-warning.btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--wa) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--wa) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--wac) / var(--tw-text-opacity));
}

.btn-outline.btn-error {
  --tw-text-opacity: 1;
  color: hsl(var(--er) / var(--tw-text-opacity));
}

.btn-outline.btn-error.btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--er) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--er) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--erc) / var(--tw-text-opacity));
}

.btn.btn-disabled, .btn[disabled], .btn:disabled {
  --tw-border-opacity: 0;
  background-color: hsl(var(--n) / var(--tw-bg-opacity));
  --tw-bg-opacity: .2;
  color: hsl(var(--bc) / var(--tw-text-opacity));
  --tw-text-opacity: .2;
}

.btn-group > input[type="radio"]:checked.btn, .btn-group > .btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--p) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--p) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--pc) / var(--tw-text-opacity));
}

.btn-group > input[type="radio"]:checked.btn:focus-visible, .btn-group > .btn-active:focus-visible {
  outline-width: 2px;
  outline-style: solid;
  outline-color: hsl(var(--p) / 1);
}

.btninput[type="checkbox"]:checked, .btninput[type="radio"]:checked {
  --tw-border-opacity: 1;
  border-color: hsl(var(--p) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--p) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--pc) / var(--tw-text-opacity));
}

.btninput[type="checkbox"]:checked:focus-visible, .btninput[type="radio"]:checked:focus-visible {
  outline-color: hsl(var(--p) / 1);
}

@keyframes button-pop {
  0% {
    transform: scale(var(--btn-focus-scale, .98));
  }

  40% {
    transform: scale(1.02);
  }

  100% {
    transform: scale(1);
  }
}

.card :where(figure:first-child) {
  border-start-start-radius: inherit;
  border-start-end-radius: inherit;
  border-end-end-radius: unset;
  border-end-start-radius: unset;
  overflow: hidden;
}

.card :where(figure:last-child) {
  border-start-start-radius: unset;
  border-start-end-radius: unset;
  border-end-end-radius: inherit;
  border-end-start-radius: inherit;
  overflow: hidden;
}

.card:focus-visible {
  outline-offset: 2px;
  outline: 2px solid;
}

.card.bordered, .card-bordered {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: hsl(var(--b2) / var(--tw-border-opacity));
}

.card.compact .card-body {
  padding: 1rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.card-title {
  align-items: center;
  gap: .5rem;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.75rem;
  display: flex;
}

.card.image-full :where(figure) {
  border-radius: inherit;
  overflow: hidden;
}

.carousel::-webkit-scrollbar {
  display: none;
}

.chat-bubble-primary {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--p) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--pc) / var(--tw-text-opacity));
}

.chat-bubble-secondary {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--s) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--sc) / var(--tw-text-opacity));
}

.chat-bubble-accent {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--a) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--ac) / var(--tw-text-opacity));
}

.chat-bubble-info {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--in) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--inc) / var(--tw-text-opacity));
}

.chat-bubble-success {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--su) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--suc) / var(--tw-text-opacity));
}

.chat-bubble-warning {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--wa) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--wac) / var(--tw-text-opacity));
}

.chat-bubble-error {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--er) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--erc) / var(--tw-text-opacity));
}

.\!checkbox:focus-visible {
  outline-offset: 2px !important;
  outline-width: 2px !important;
  outline-style: solid !important;
  outline-color: hsl(var(--bc) / 1) !important;
}

.checkbox:focus-visible {
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
  outline-color: hsl(var(--bc) / 1);
}

.checkbox:checked, .checkbox[checked="true"], .checkbox[aria-checked="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--bc) / var(--tw-bg-opacity));
  animation: checkmark var(--animation-input, .2s) ease-out;
  background-repeat: no-repeat;
  background-image: linear-gradient(-45deg, transparent 65%, hsl(var(--chkbg)) 65.99%), linear-gradient(45deg, transparent 75%, hsl(var(--chkbg)) 75.99%), linear-gradient(-45deg, hsl(var(--chkbg)) 40%, transparent 40.99%), linear-gradient(45deg, hsl(var(--chkbg)) 30%, hsl(var(--chkfg)) 30.99%, hsl(var(--chkfg)) 40%, transparent 40.99%), linear-gradient(-45deg, hsl(var(--chkfg)) 50%, hsl(var(--chkbg)) 50.99%);
}

.\!checkbox:checked, .\!checkbox[checked="true"], .\!checkbox[aria-checked="true"] {
  --tw-bg-opacity: 1 !important;
  background-color: hsl(var(--bc) / var(--tw-bg-opacity)) !important;
  animation: checkmark var(--animation-input, .2s) ease-out !important;
  background-repeat: no-repeat !important;
  background-image: linear-gradient(-45deg, transparent 65%, hsl(var(--chkbg)) 65.99%), linear-gradient(45deg, transparent 75%, hsl(var(--chkbg)) 75.99%), linear-gradient(-45deg, hsl(var(--chkbg)) 40%, transparent 40.99%), linear-gradient(45deg, hsl(var(--chkbg)) 30%, hsl(var(--chkfg)) 30.99%, hsl(var(--chkfg)) 40%, transparent 40.99%), linear-gradient(-45deg, hsl(var(--chkfg)) 50%, hsl(var(--chkbg)) 50.99%) !important;
}

.\!checkbox:indeterminate {
  --tw-bg-opacity: 1 !important;
  background-color: hsl(var(--bc) / var(--tw-bg-opacity)) !important;
  animation: checkmark var(--animation-input, .2s) ease-out !important;
  background-repeat: no-repeat !important;
  background-image: linear-gradient(90deg, transparent 80%, hsl(var(--chkbg)) 80%), linear-gradient(-90deg, transparent 80%, hsl(var(--chkbg)) 80%), linear-gradient(0deg, hsl(var(--chkbg)) 43%, hsl(var(--chkfg)) 43%, hsl(var(--chkfg)) 57%, hsl(var(--chkbg)) 57%) !important;
}

.checkbox:indeterminate {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--bc) / var(--tw-bg-opacity));
  animation: checkmark var(--animation-input, .2s) ease-out;
  background-repeat: no-repeat;
  background-image: linear-gradient(90deg, transparent 80%, hsl(var(--chkbg)) 80%), linear-gradient(-90deg, transparent 80%, hsl(var(--chkbg)) 80%), linear-gradient(0deg, hsl(var(--chkbg)) 43%, hsl(var(--chkfg)) 43%, hsl(var(--chkfg)) 57%, hsl(var(--chkbg)) 57%);
}

.checkbox-primary {
  --chkbg: var(--p);
  --chkfg: var(--pc);
  --tw-border-opacity: 1;
  border-color: hsl(var(--p) / var(--tw-border-opacity));
}

.checkbox-primary:focus-visible {
  outline-color: hsl(var(--p) / 1);
}

.checkbox-primary:checked, .checkbox-primary[checked="true"], .checkbox-primary[aria-checked="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--p) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--p) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--pc) / var(--tw-text-opacity));
}

.checkbox-secondary {
  --chkbg: var(--s);
  --chkfg: var(--sc);
  --tw-border-opacity: 1;
  border-color: hsl(var(--s) / var(--tw-border-opacity));
}

.checkbox-secondary:focus-visible {
  outline-color: hsl(var(--s) / 1);
}

.checkbox-secondary:checked, .checkbox-secondary[checked="true"], .checkbox-secondary[aria-checked="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--s) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--s) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--sc) / var(--tw-text-opacity));
}

.checkbox-accent {
  --chkbg: var(--a);
  --chkfg: var(--ac);
  --tw-border-opacity: 1;
  border-color: hsl(var(--a) / var(--tw-border-opacity));
}

.checkbox-accent:focus-visible {
  outline-color: hsl(var(--a) / 1);
}

.checkbox-accent:checked, .checkbox-accent[checked="true"], .checkbox-accent[aria-checked="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--a) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--a) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--ac) / var(--tw-text-opacity));
}

.checkbox-success {
  --chkbg: var(--su);
  --chkfg: var(--suc);
  --tw-border-opacity: 1;
  border-color: hsl(var(--su) / var(--tw-border-opacity));
}

.checkbox-success:focus-visible {
  outline-color: hsl(var(--su) / 1);
}

.checkbox-success:checked, .checkbox-success[checked="true"], .checkbox-success[aria-checked="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--su) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--su) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--suc) / var(--tw-text-opacity));
}

.checkbox-warning {
  --chkbg: var(--wa);
  --chkfg: var(--wac);
  --tw-border-opacity: 1;
  border-color: hsl(var(--wa) / var(--tw-border-opacity));
}

.checkbox-warning:focus-visible {
  outline-color: hsl(var(--wa) / 1);
}

.checkbox-warning:checked, .checkbox-warning[checked="true"], .checkbox-warning[aria-checked="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--wa) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--wa) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--wac) / var(--tw-text-opacity));
}

.checkbox-info {
  --chkbg: var(--in);
  --chkfg: var(--inc);
  --tw-border-opacity: 1;
  border-color: hsl(var(--in) / var(--tw-border-opacity));
}

.checkbox-info:focus-visible {
  outline-color: hsl(var(--in) / 1);
}

.checkbox-info:checked, .checkbox-info[checked="true"], .checkbox-info[aria-checked="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--in) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--in) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--inc) / var(--tw-text-opacity));
}

.checkbox-error {
  --chkbg: var(--er);
  --chkfg: var(--erc);
  --tw-border-opacity: 1;
  border-color: hsl(var(--er) / var(--tw-border-opacity));
}

.checkbox-error:focus-visible {
  outline-color: hsl(var(--er) / 1);
}

.checkbox-error:checked, .checkbox-error[checked="true"], .checkbox-error[aria-checked="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--er) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--er) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--erc) / var(--tw-text-opacity));
}

.\!checkbox:disabled {
  cursor: not-allowed !important;
  --tw-bg-opacity: 1 !important;
  background-color: hsl(var(--bc) / var(--tw-bg-opacity)) !important;
  opacity: .2 !important;
  border-color: #0000 !important;
}

.checkbox:disabled {
  cursor: not-allowed;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--bc) / var(--tw-bg-opacity));
  opacity: .2;
  border-color: #0000;
}

@keyframes checkmark {
  0% {
    background-position-y: 5px;
  }

  50% {
    background-position-y: -2px;
  }

  100% {
    background-position-y: 0;
  }
}

.checkbox-mark {
  display: none;
}

[dir="rtl"] .checkbox:checked, [dir="rtl"] .checkbox[checked="true"], [dir="rtl"] .checkbox[aria-checked="true"] {
  background-image: linear-gradient(45deg, transparent 65%, hsl(var(--chkbg)) 65.99%), linear-gradient(-45deg, transparent 75%, hsl(var(--chkbg)) 75.99%), linear-gradient(45deg, hsl(var(--chkbg)) 40%, transparent 40.99%), linear-gradient(-45deg, hsl(var(--chkbg)) 30%, hsl(var(--chkfg)) 30.99%, hsl(var(--chkfg)) 40%, transparent 40.99%), linear-gradient(45deg, hsl(var(--chkfg)) 50%, hsl(var(--chkbg)) 50.99%);
}

[dir="rtl"] .\!checkbox:checked, [dir="rtl"] .\!checkbox[checked="true"], [dir="rtl"] .\!checkbox[aria-checked="true"] {
  background-image: linear-gradient(45deg, transparent 65%, hsl(var(--chkbg)) 65.99%), linear-gradient(-45deg, transparent 75%, hsl(var(--chkbg)) 75.99%), linear-gradient(45deg, hsl(var(--chkbg)) 40%, transparent 40.99%), linear-gradient(-45deg, hsl(var(--chkbg)) 30%, hsl(var(--chkfg)) 30.99%, hsl(var(--chkfg)) 40%, transparent 40.99%), linear-gradient(45deg, hsl(var(--chkfg)) 50%, hsl(var(--chkbg)) 50.99%) !important;
}

details.collapse {
  width: 100%;
}

details.collapse summary {
  outline-offset: 2px;
  outline: 2px solid #0000;
  display: block;
  position: relative;
}

details.collapse summary::-webkit-details-marker {
  display: none;
}

.collapse:focus-visible, :is(.collapse:has(.collapse-title:focus-visible), .collapse:has( > input[type="checkbox"]:focus-visible), .collapse:has( > input[type="radio"]:focus-visible)) {
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
  outline-color: hsl(var(--bc) / 1);
}

.collapse-arrow .collapse-title:after {
  --tw-translate-y: -100%;
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  content: "";
  transform-origin: 75% 75%;
  pointer-events: none;
  width: .5rem;
  height: .5rem;
  transition-property: all;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
  display: block;
  position: absolute;
  top: 50%;
  right: 1.4rem;
  box-shadow: 2px 2px;
}

[dir="rtl"] .collapse-arrow .collapse-title:after {
  --tw-rotate: -45deg;
}

.collapse-plus .collapse-title:after {
  content: "+";
  pointer-events: none;
  width: .5rem;
  height: .5rem;
  transition-property: all;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
  display: block;
  position: absolute;
  top: .9rem;
  right: 1.4rem;
}

.collapse:not(.collapse-open):not(.collapse-close) input[type="checkbox"], .collapse:not(.collapse-open):not(.collapse-close) input[type="radio"]:not(:checked), .collapse:not(.collapse-open):not(.collapse-close) .collapse-title {
  cursor: pointer;
}

.collapse:focus:not(.collapse-open):not(.collapse-close):not(.collapse[open]) .collapse-title {
  cursor: unset;
}

.collapse-title {
  position: relative;
}

:where(.collapse > input[type="checkbox"]), :where(.collapse > input[type="radio"]) {
  z-index: 1;
}

.collapse-title, :where(.collapse > input[type="checkbox"]), :where(.collapse > input[type="radio"]) {
  width: 100%;
  min-height: 3.75rem;
  padding: 1rem 3rem 1rem 1rem;
  transition: background-color .2s ease-out;
}

.collapse[open] :where(.collapse-content), .collapse-open :where(.collapse-content), .collapse:focus:not(.collapse-close) :where(.collapse-content), .collapse:not(.collapse-close) :where(input[type="checkbox"]:checked ~ .collapse-content), .collapse:not(.collapse-close) :where(input[type="radio"]:checked ~ .collapse-content) {
  padding-bottom: 1rem;
  transition: padding .2s ease-out, background-color .2s ease-out;
}

.collapse[open].collapse-arrow .collapse-title:after, .collapse-open.collapse-arrow .collapse-title:after, .collapse-arrow:focus:not(.collapse-close) .collapse-title:after, .collapse-arrow:not(.collapse-close) input[type="checkbox"]:checked ~ .collapse-title:after, .collapse-arrow:not(.collapse-close) input[type="radio"]:checked ~ .collapse-title:after {
  --tw-translate-y: -50%;
  --tw-rotate: 225deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

[dir="rtl"] .collapse[open].collapse-arrow .collapse-title:after, [dir="rtl"] .collapse-open.collapse-arrow .collapse-title:after, [dir="rtl"] .collapse-arrow:focus:not(.collapse-close) .collapse-title:after, [dir="rtl"] .collapse-arrow:not(.collapse-close) input[type="checkbox"]:checked ~ .collapse-title:after {
  --tw-rotate: 135deg;
}

.collapse[open].collapse-plus .collapse-title:after, .collapse-open.collapse-plus .collapse-title:after, .collapse-plus:focus:not(.collapse-close) .collapse-title:after, .collapse-plus:not(.collapse-close) input[type="checkbox"]:checked ~ .collapse-title:after, .collapse-plus:not(.collapse-close) input[type="radio"]:checked ~ .collapse-title:after {
  content: "−";
}

.divider:before, .divider:after {
  background-color: hsl(var(--bc) / var(--tw-bg-opacity));
  --tw-bg-opacity: .1;
}

.divider:not(:empty) {
  gap: 1rem;
}

.drawer-toggle:checked ~ .drawer-side > .drawer-overlay {
  background-color: #0006;
}

.drawer-toggle:focus-visible ~ .drawer-content label.drawer-button {
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
}

.dropdown.dropdown-open .dropdown-content, .dropdown:focus .dropdown-content, .dropdown:focus-within .dropdown-content {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.file-input-bordered {
  --tw-border-opacity: .2;
}

.file-input:focus {
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
  outline-color: hsl(var(--bc) / .2);
}

.file-input-ghost {
  --tw-bg-opacity: .05;
}

.file-input-ghost:focus {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity));
  box-shadow: none;
}

.file-input-ghost::file-selector-button {
  color: currentColor;
  background-color: #0000;
  border-width: 1px;
  border-color: #0000;
}

.file-input-primary {
  --tw-border-opacity: 1;
  border-color: hsl(var(--p) / var(--tw-border-opacity));
}

.file-input-primary:focus {
  outline-color: hsl(var(--p) / 1);
}

.file-input-primary::file-selector-button {
  --tw-border-opacity: 1;
  border-color: hsl(var(--p) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--p) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--pc) / var(--tw-text-opacity));
}

.file-input-secondary {
  --tw-border-opacity: 1;
  border-color: hsl(var(--s) / var(--tw-border-opacity));
}

.file-input-secondary:focus {
  outline-color: hsl(var(--s) / 1);
}

.file-input-secondary::file-selector-button {
  --tw-border-opacity: 1;
  border-color: hsl(var(--s) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--s) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--sc) / var(--tw-text-opacity));
}

.file-input-accent {
  --tw-border-opacity: 1;
  border-color: hsl(var(--a) / var(--tw-border-opacity));
}

.file-input-accent:focus {
  outline-color: hsl(var(--a) / 1);
}

.file-input-accent::file-selector-button {
  --tw-border-opacity: 1;
  border-color: hsl(var(--a) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--a) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--ac) / var(--tw-text-opacity));
}

.file-input-info {
  --tw-border-opacity: 1;
  border-color: hsl(var(--in) / var(--tw-border-opacity));
}

.file-input-info:focus {
  outline-color: hsl(var(--in) / 1);
}

.file-input-info::file-selector-button {
  --tw-border-opacity: 1;
  border-color: hsl(var(--in) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--in) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--inc) / var(--tw-text-opacity));
}

.file-input-success {
  --tw-border-opacity: 1;
  border-color: hsl(var(--su) / var(--tw-border-opacity));
}

.file-input-success:focus {
  outline-color: hsl(var(--su) / 1);
}

.file-input-success::file-selector-button {
  --tw-border-opacity: 1;
  border-color: hsl(var(--su) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--su) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--suc) / var(--tw-text-opacity));
}

.file-input-warning {
  --tw-border-opacity: 1;
  border-color: hsl(var(--wa) / var(--tw-border-opacity));
}

.file-input-warning:focus {
  outline-color: hsl(var(--wa) / 1);
}

.file-input-warning::file-selector-button {
  --tw-border-opacity: 1;
  border-color: hsl(var(--wa) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--wa) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--wac) / var(--tw-text-opacity));
}

.file-input-error {
  --tw-border-opacity: 1;
  border-color: hsl(var(--er) / var(--tw-border-opacity));
}

.file-input-error:focus {
  outline-color: hsl(var(--er) / 1);
}

.file-input-error::file-selector-button {
  --tw-border-opacity: 1;
  border-color: hsl(var(--er) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--er) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--erc) / var(--tw-text-opacity));
}

.file-input-disabled, .file-input[disabled] {
  cursor: not-allowed;
  --tw-border-opacity: 1;
  border-color: hsl(var(--b2) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2) / var(--tw-bg-opacity));
  --tw-text-opacity: .2;
}

.file-input-disabled::placeholder, .file-input[disabled]::placeholder {
  color: hsl(var(--bc) / var(--tw-placeholder-opacity));
  --tw-placeholder-opacity: .2;
}

.file-input-disabled::file-selector-button {
  --tw-border-opacity: 0;
  background-color: hsl(var(--n) / var(--tw-bg-opacity));
  --tw-bg-opacity: .2;
  color: hsl(var(--bc) / var(--tw-text-opacity));
  --tw-text-opacity: .2;
}

.file-input[disabled]::file-selector-button {
  --tw-border-opacity: 0;
  background-color: hsl(var(--n) / var(--tw-bg-opacity));
  --tw-bg-opacity: .2;
  color: hsl(var(--bc) / var(--tw-text-opacity));
  --tw-text-opacity: .2;
}

.footer-title {
  text-transform: uppercase;
  opacity: .5;
  margin-bottom: .5rem;
  font-weight: 700;
}

.label-text {
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity));
  font-size: .875rem;
  line-height: 1.25rem;
}

.label-text-alt {
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity));
  font-size: .75rem;
  line-height: 1rem;
}

.input[list]::-webkit-calendar-picker-indicator {
  line-height: 1em;
}

.input-bordered {
  --tw-border-opacity: .2;
}

.input:focus {
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
  outline-color: hsl(var(--bc) / .2);
}

.input-ghost {
  --tw-bg-opacity: .05;
}

.input-ghost:focus {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity));
  box-shadow: none;
}

.input-primary {
  --tw-border-opacity: 1;
  border-color: hsl(var(--p) / var(--tw-border-opacity));
}

.input-primary:focus {
  outline-color: hsl(var(--p) / 1);
}

.input-secondary {
  --tw-border-opacity: 1;
  border-color: hsl(var(--s) / var(--tw-border-opacity));
}

.input-secondary:focus {
  outline-color: hsl(var(--s) / 1);
}

.input-accent {
  --tw-border-opacity: 1;
  border-color: hsl(var(--a) / var(--tw-border-opacity));
}

.input-accent:focus {
  outline-color: hsl(var(--a) / 1);
}

.input-info {
  --tw-border-opacity: 1;
  border-color: hsl(var(--in) / var(--tw-border-opacity));
}

.input-info:focus {
  outline-color: hsl(var(--in) / 1);
}

.input-success {
  --tw-border-opacity: 1;
  border-color: hsl(var(--su) / var(--tw-border-opacity));
}

.input-success:focus {
  outline-color: hsl(var(--su) / 1);
}

.input-warning {
  --tw-border-opacity: 1;
  border-color: hsl(var(--wa) / var(--tw-border-opacity));
}

.input-warning:focus {
  outline-color: hsl(var(--wa) / 1);
}

.input-error {
  --tw-border-opacity: 1;
  border-color: hsl(var(--er) / var(--tw-border-opacity));
}

.input-error:focus {
  outline-color: hsl(var(--er) / 1);
}

.input-disabled, .input:disabled, .input[disabled] {
  cursor: not-allowed;
  --tw-border-opacity: 1;
  border-color: hsl(var(--b2) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2) / var(--tw-bg-opacity));
  --tw-text-opacity: .2;
}

.input-disabled::placeholder, .input:disabled::placeholder, .input[disabled]::placeholder {
  color: hsl(var(--bc) / var(--tw-placeholder-opacity));
  --tw-placeholder-opacity: .2;
}

.join > :where(:not(:first-child)) {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: -1px;
}

.join-item:focus {
  isolation: isolate;
}

.link-primary {
  --tw-text-opacity: 1;
  color: hsl(var(--p) / var(--tw-text-opacity));
}

.link-secondary {
  --tw-text-opacity: 1;
  color: hsl(var(--s) / var(--tw-text-opacity));
}

.link-accent {
  --tw-text-opacity: 1;
  color: hsl(var(--a) / var(--tw-text-opacity));
}

.link-neutral {
  --tw-text-opacity: 1;
  color: hsl(var(--n) / var(--tw-text-opacity));
}

.link-success {
  --tw-text-opacity: 1;
  color: hsl(var(--su) / var(--tw-text-opacity));
}

.link-info {
  --tw-text-opacity: 1;
  color: hsl(var(--in) / var(--tw-text-opacity));
}

.link-warning {
  --tw-text-opacity: 1;
  color: hsl(var(--wa) / var(--tw-text-opacity));
}

.link-error {
  --tw-text-opacity: 1;
  color: hsl(var(--er) / var(--tw-text-opacity));
}

.link:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.link:focus-visible {
  outline-offset: 2px;
  outline: 2px solid;
}

.\!loading {
  pointer-events: none !important;
  aspect-ratio: 1 !important;
  background-color: currentColor !important;
  width: 1.5rem !important;
  display: inline-block !important;
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg width='24' height='24' stroke='%23000' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cstyle%3E.spinner_V8m1%7Btransform-origin:center;animation:spinner_zKoa 2s linear infinite%7D.spinner_V8m1 circle%7Bstroke-linecap:round;animation:spinner_YpZS 1.5s ease-out infinite%7D%40keyframes spinner_zKoa%7B100%25%7Btransform:rotate(360deg)%7D%7D%40keyframes spinner_YpZS%7B0%25%7Bstroke-dasharray:0 150;stroke-dashoffset:0%7D47.5%25%7Bstroke-dasharray:42 150;stroke-dashoffset:-16%7D95%25%2C100%25%7Bstroke-dasharray:42 150;stroke-dashoffset:-59%7D%7D%3C%2Fstyle%3E%3Cg class='spinner_V8m1'%3E%3Ccircle cx='12' cy='12' r='9.5' fill='none' stroke-width='3'%3E%3C%2Fcircle%3E%3C%2Fg%3E%3C%2Fsvg%3E") !important;
  mask-image: url("data:image/svg+xml,%3Csvg width='24' height='24' stroke='%23000' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cstyle%3E.spinner_V8m1%7Btransform-origin:center;animation:spinner_zKoa 2s linear infinite%7D.spinner_V8m1 circle%7Bstroke-linecap:round;animation:spinner_YpZS 1.5s ease-out infinite%7D%40keyframes spinner_zKoa%7B100%25%7Btransform:rotate(360deg)%7D%7D%40keyframes spinner_YpZS%7B0%25%7Bstroke-dasharray:0 150;stroke-dashoffset:0%7D47.5%25%7Bstroke-dasharray:42 150;stroke-dashoffset:-16%7D95%25%2C100%25%7Bstroke-dasharray:42 150;stroke-dashoffset:-59%7D%7D%3C%2Fstyle%3E%3Cg class='spinner_V8m1'%3E%3Ccircle cx='12' cy='12' r='9.5' fill='none' stroke-width='3'%3E%3C%2Fcircle%3E%3C%2Fg%3E%3C%2Fsvg%3E") !important;
  -webkit-mask-position: center !important;
  mask-position: center !important;
  -webkit-mask-size: 100% !important;
  mask-size: 100% !important;
  -webkit-mask-repeat: no-repeat !important;
  mask-repeat: no-repeat !important;
}

.loading {
  pointer-events: none;
  aspect-ratio: 1;
  background-color: currentColor;
  width: 1.5rem;
  display: inline-block;
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg width='24' height='24' stroke='%23000' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cstyle%3E.spinner_V8m1%7Btransform-origin:center;animation:spinner_zKoa 2s linear infinite%7D.spinner_V8m1 circle%7Bstroke-linecap:round;animation:spinner_YpZS 1.5s ease-out infinite%7D%40keyframes spinner_zKoa%7B100%25%7Btransform:rotate(360deg)%7D%7D%40keyframes spinner_YpZS%7B0%25%7Bstroke-dasharray:0 150;stroke-dashoffset:0%7D47.5%25%7Bstroke-dasharray:42 150;stroke-dashoffset:-16%7D95%25%2C100%25%7Bstroke-dasharray:42 150;stroke-dashoffset:-59%7D%7D%3C%2Fstyle%3E%3Cg class='spinner_V8m1'%3E%3Ccircle cx='12' cy='12' r='9.5' fill='none' stroke-width='3'%3E%3C%2Fcircle%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  mask-image: url("data:image/svg+xml,%3Csvg width='24' height='24' stroke='%23000' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cstyle%3E.spinner_V8m1%7Btransform-origin:center;animation:spinner_zKoa 2s linear infinite%7D.spinner_V8m1 circle%7Bstroke-linecap:round;animation:spinner_YpZS 1.5s ease-out infinite%7D%40keyframes spinner_zKoa%7B100%25%7Btransform:rotate(360deg)%7D%7D%40keyframes spinner_YpZS%7B0%25%7Bstroke-dasharray:0 150;stroke-dashoffset:0%7D47.5%25%7Bstroke-dasharray:42 150;stroke-dashoffset:-16%7D95%25%2C100%25%7Bstroke-dasharray:42 150;stroke-dashoffset:-59%7D%7D%3C%2Fstyle%3E%3Cg class='spinner_V8m1'%3E%3Ccircle cx='12' cy='12' r='9.5' fill='none' stroke-width='3'%3E%3C%2Fcircle%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.loading-spinner {
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg width='24' height='24' stroke='%23000' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cstyle%3E.spinner_V8m1%7Btransform-origin:center;animation:spinner_zKoa 2s linear infinite%7D.spinner_V8m1 circle%7Bstroke-linecap:round;animation:spinner_YpZS 1.5s ease-out infinite%7D%40keyframes spinner_zKoa%7B100%25%7Btransform:rotate(360deg)%7D%7D%40keyframes spinner_YpZS%7B0%25%7Bstroke-dasharray:0 150;stroke-dashoffset:0%7D47.5%25%7Bstroke-dasharray:42 150;stroke-dashoffset:-16%7D95%25%2C100%25%7Bstroke-dasharray:42 150;stroke-dashoffset:-59%7D%7D%3C%2Fstyle%3E%3Cg class='spinner_V8m1'%3E%3Ccircle cx='12' cy='12' r='9.5' fill='none' stroke-width='3'%3E%3C%2Fcircle%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  mask-image: url("data:image/svg+xml,%3Csvg width='24' height='24' stroke='%23000' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cstyle%3E.spinner_V8m1%7Btransform-origin:center;animation:spinner_zKoa 2s linear infinite%7D.spinner_V8m1 circle%7Bstroke-linecap:round;animation:spinner_YpZS 1.5s ease-out infinite%7D%40keyframes spinner_zKoa%7B100%25%7Btransform:rotate(360deg)%7D%7D%40keyframes spinner_YpZS%7B0%25%7Bstroke-dasharray:0 150;stroke-dashoffset:0%7D47.5%25%7Bstroke-dasharray:42 150;stroke-dashoffset:-16%7D95%25%2C100%25%7Bstroke-dasharray:42 150;stroke-dashoffset:-59%7D%7D%3C%2Fstyle%3E%3Cg class='spinner_V8m1'%3E%3Ccircle cx='12' cy='12' r='9.5' fill='none' stroke-width='3'%3E%3C%2Fcircle%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}

.loading-dots {
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cstyle%3E.spinner_qM83%7Banimation:spinner_8HQG 1.05s infinite%7D.spinner_oXPr%7Banimation-delay:.1s%7D.spinner_ZTLf%7Banimation-delay:.2s%7D@keyframes spinner_8HQG%7B0%25,57.14%25%7Banimation-timing-function:cubic-bezier(0.33,.66,.66,1);transform:translate(0)%7D28.57%25%7Banimation-timing-function:cubic-bezier(0.33,0,.66,.33);transform:translateY(-6px)%7D100%25%7Btransform:translate(0)%7D%7D%3C/style%3E%3Ccircle class='spinner_qM83' cx='4' cy='12' r='3'/%3E%3Ccircle class='spinner_qM83 spinner_oXPr' cx='12' cy='12' r='3'/%3E%3Ccircle class='spinner_qM83 spinner_ZTLf' cx='20' cy='12' r='3'/%3E%3C/svg%3E");
  mask-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cstyle%3E.spinner_qM83%7Banimation:spinner_8HQG 1.05s infinite%7D.spinner_oXPr%7Banimation-delay:.1s%7D.spinner_ZTLf%7Banimation-delay:.2s%7D@keyframes spinner_8HQG%7B0%25,57.14%25%7Banimation-timing-function:cubic-bezier(0.33,.66,.66,1);transform:translate(0)%7D28.57%25%7Banimation-timing-function:cubic-bezier(0.33,0,.66,.33);transform:translateY(-6px)%7D100%25%7Btransform:translate(0)%7D%7D%3C/style%3E%3Ccircle class='spinner_qM83' cx='4' cy='12' r='3'/%3E%3Ccircle class='spinner_qM83 spinner_oXPr' cx='12' cy='12' r='3'/%3E%3Ccircle class='spinner_qM83 spinner_ZTLf' cx='20' cy='12' r='3'/%3E%3C/svg%3E");
}

.loading-ring {
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg width='44' height='44' viewBox='0 0 44 44' xmlns='http://www.w3.org/2000/svg' stroke='%23fff'%3E%3Cg fill='none' fill-rule='evenodd' stroke-width='2'%3E%3Ccircle cx='22' cy='22' r='1'%3E%3Canimate attributeName='r' begin='0s' dur='1.8s' values='1; 20' calcMode='spline' keyTimes='0; 1' keySplines='0.165, 0.84, 0.44, 1' repeatCount='indefinite' /%3E%3Canimate attributeName='stroke-opacity' begin='0s' dur='1.8s' values='1; 0' calcMode='spline' keyTimes='0; 1' keySplines='0.3, 0.61, 0.355, 1' repeatCount='indefinite' /%3E%3C/circle%3E%3Ccircle cx='22' cy='22' r='1'%3E%3Canimate attributeName='r' begin='-0.9s' dur='1.8s' values='1; 20' calcMode='spline' keyTimes='0; 1' keySplines='0.165, 0.84, 0.44, 1' repeatCount='indefinite' /%3E%3Canimate attributeName='stroke-opacity' begin='-0.9s' dur='1.8s' values='1; 0' calcMode='spline' keyTimes='0; 1' keySplines='0.3, 0.61, 0.355, 1' repeatCount='indefinite' /%3E%3C/circle%3E%3C/g%3E%3C/svg%3E");
  mask-image: url("data:image/svg+xml,%3Csvg width='44' height='44' viewBox='0 0 44 44' xmlns='http://www.w3.org/2000/svg' stroke='%23fff'%3E%3Cg fill='none' fill-rule='evenodd' stroke-width='2'%3E%3Ccircle cx='22' cy='22' r='1'%3E%3Canimate attributeName='r' begin='0s' dur='1.8s' values='1; 20' calcMode='spline' keyTimes='0; 1' keySplines='0.165, 0.84, 0.44, 1' repeatCount='indefinite' /%3E%3Canimate attributeName='stroke-opacity' begin='0s' dur='1.8s' values='1; 0' calcMode='spline' keyTimes='0; 1' keySplines='0.3, 0.61, 0.355, 1' repeatCount='indefinite' /%3E%3C/circle%3E%3Ccircle cx='22' cy='22' r='1'%3E%3Canimate attributeName='r' begin='-0.9s' dur='1.8s' values='1; 20' calcMode='spline' keyTimes='0; 1' keySplines='0.165, 0.84, 0.44, 1' repeatCount='indefinite' /%3E%3Canimate attributeName='stroke-opacity' begin='-0.9s' dur='1.8s' values='1; 0' calcMode='spline' keyTimes='0; 1' keySplines='0.3, 0.61, 0.355, 1' repeatCount='indefinite' /%3E%3C/circle%3E%3C/g%3E%3C/svg%3E");
}

.loading-ball {
  -webkit-mask-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cstyle%3E.spinner_rXNP%7Banimation:spinner_YeBj .8s infinite%7D@keyframes spinner_YeBj%7B0%25%7Banimation-timing-function:cubic-bezier(0.33,0,.66,.33);cy:5px%7D46.875%25%7Bcy:20px;rx:4px;ry:4px%7D50%25%7Banimation-timing-function:cubic-bezier(0.33,.66,.66,1);cy:20.5px;rx:4.8px;ry:3px%7D53.125%25%7Brx:4px;ry:4px%7D100%25%7Bcy:5px%7D%7D%3C/style%3E%3Cellipse class='spinner_rXNP' cx='12' cy='5' rx='4' ry='4'/%3E%3C/svg%3E");
  mask-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cstyle%3E.spinner_rXNP%7Banimation:spinner_YeBj .8s infinite%7D@keyframes spinner_YeBj%7B0%25%7Banimation-timing-function:cubic-bezier(0.33,0,.66,.33);cy:5px%7D46.875%25%7Bcy:20px;rx:4px;ry:4px%7D50%25%7Banimation-timing-function:cubic-bezier(0.33,.66,.66,1);cy:20.5px;rx:4.8px;ry:3px%7D53.125%25%7Brx:4px;ry:4px%7D100%25%7Bcy:5px%7D%7D%3C/style%3E%3Cellipse class='spinner_rXNP' cx='12' cy='5' rx='4' ry='4'/%3E%3C/svg%3E");
}

.loading-bars {
  -webkit-mask-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cstyle%3E.spinner_hzlK%7Banimation:spinner_vc4H .8s linear infinite;animation-delay:-.8s%7D.spinner_koGT%7Banimation-delay:-.65s%7D.spinner_YF1u%7Banimation-delay:-.5s%7D@keyframes spinner_vc4H%7B0%25%7By:1px;height:22px%7D93.75%25%7By:5px;height:14px;opacity:.2%7D%7D%3C/style%3E%3Crect class='spinner_hzlK' x='1' y='1' width='6' height='22'/%3E%3Crect class='spinner_hzlK spinner_koGT' x='9' y='1' width='6' height='22'/%3E%3Crect class='spinner_hzlK spinner_YF1u' x='17' y='1' width='6' height='22'/%3E%3C/svg%3E");
  mask-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cstyle%3E.spinner_hzlK%7Banimation:spinner_vc4H .8s linear infinite;animation-delay:-.8s%7D.spinner_koGT%7Banimation-delay:-.65s%7D.spinner_YF1u%7Banimation-delay:-.5s%7D@keyframes spinner_vc4H%7B0%25%7By:1px;height:22px%7D93.75%25%7By:5px;height:14px;opacity:.2%7D%7D%3C/style%3E%3Crect class='spinner_hzlK' x='1' y='1' width='6' height='22'/%3E%3Crect class='spinner_hzlK spinner_koGT' x='9' y='1' width='6' height='22'/%3E%3Crect class='spinner_hzlK spinner_YF1u' x='17' y='1' width='6' height='22'/%3E%3C/svg%3E");
}

.loading-infinity {
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' style='shape-rendering: auto;' width='200px' height='200px' viewBox='0 0 100 100' preserveAspectRatio='xMidYMid'%3E%3Cpath fill='none' stroke='%230a0a0a' stroke-width='10' stroke-dasharray='205.271142578125 51.317785644531256' d='M24.3 30C11.4 30 5 43.3 5 50s6.4 20 19.3 20c19.3 0 32.1-40 51.4-40 C88.6 30 95 43.3 95 50s-6.4 20-19.3 20C56.4 70 43.6 30 24.3 30z' stroke-linecap='round' style='transform:scale(0.8);transform-origin:50px 50px'%3E%3Canimate attributeName='stroke-dashoffset' repeatCount='indefinite' dur='2s' keyTimes='0;1' values='0;256.58892822265625'%3E%3C/animate%3E%3C/path%3E%3C/svg%3E");
  mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' style='shape-rendering: auto;' width='200px' height='200px' viewBox='0 0 100 100' preserveAspectRatio='xMidYMid'%3E%3Cpath fill='none' stroke='%230a0a0a' stroke-width='10' stroke-dasharray='205.271142578125 51.317785644531256' d='M24.3 30C11.4 30 5 43.3 5 50s6.4 20 19.3 20c19.3 0 32.1-40 51.4-40 C88.6 30 95 43.3 95 50s-6.4 20-19.3 20C56.4 70 43.6 30 24.3 30z' stroke-linecap='round' style='transform:scale(0.8);transform-origin:50px 50px'%3E%3Canimate attributeName='stroke-dashoffset' repeatCount='indefinite' dur='2s' keyTimes='0;1' values='0;256.58892822265625'%3E%3C/animate%3E%3C/path%3E%3C/svg%3E");
}

.loading-xs {
  width: 1rem;
}

.loading-sm {
  width: 1.25rem;
}

.loading-md {
  width: 1.5rem;
}

.loading-lg {
  width: 2.5rem;
}

.mask-squircle {
  -webkit-mask-image: url("data:image/svg+xml,%3csvg width='200' height='200' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M100 0C20 0 0 20 0 100s20 100 100 100 100-20 100-100S180 0 100 0Z'/%3e%3c/svg%3e");
  mask-image: url("data:image/svg+xml,%3csvg width='200' height='200' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M100 0C20 0 0 20 0 100s20 100 100 100 100-20 100-100S180 0 100 0Z'/%3e%3c/svg%3e");
}

.mask-decagon {
  -webkit-mask-image: url("data:image/svg+xml,%3csvg width='192' height='200' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='black' d='m96 0 58.779 19.098 36.327 50v61.804l-36.327 50L96 200l-58.779-19.098-36.327-50V69.098l36.327-50z' fill-rule='evenodd'/%3e%3c/svg%3e");
  mask-image: url("data:image/svg+xml,%3csvg width='192' height='200' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='black' d='m96 0 58.779 19.098 36.327 50v61.804l-36.327 50L96 200l-58.779-19.098-36.327-50V69.098l36.327-50z' fill-rule='evenodd'/%3e%3c/svg%3e");
}

.mask-diamond {
  -webkit-mask-image: url("data:image/svg+xml,%3csvg width='200' height='200' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='black' d='m100 0 100 100-100 100L0 100z' fill-rule='evenodd'/%3e%3c/svg%3e");
  mask-image: url("data:image/svg+xml,%3csvg width='200' height='200' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='black' d='m100 0 100 100-100 100L0 100z' fill-rule='evenodd'/%3e%3c/svg%3e");
}

.mask-heart {
  -webkit-mask-image: url("data:image/svg+xml,%3csvg width='200' height='185' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M100 184.606a15.384 15.384 0 0 1-8.653-2.678C53.565 156.28 37.205 138.695 28.182 127.7 8.952 104.264-.254 80.202.005 54.146.308 24.287 24.264 0 53.406 0c21.192 0 35.869 11.937 44.416 21.879a2.884 2.884 0 0 0 4.356 0C110.725 11.927 125.402 0 146.594 0c29.142 0 53.098 24.287 53.4 54.151.26 26.061-8.956 50.122-28.176 73.554-9.023 10.994-25.383 28.58-63.165 54.228a15.384 15.384 0 0 1-8.653 2.673Z' fill='black' fill-rule='nonzero'/%3e%3c/svg%3e");
  mask-image: url("data:image/svg+xml,%3csvg width='200' height='185' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M100 184.606a15.384 15.384 0 0 1-8.653-2.678C53.565 156.28 37.205 138.695 28.182 127.7 8.952 104.264-.254 80.202.005 54.146.308 24.287 24.264 0 53.406 0c21.192 0 35.869 11.937 44.416 21.879a2.884 2.884 0 0 0 4.356 0C110.725 11.927 125.402 0 146.594 0c29.142 0 53.098 24.287 53.4 54.151.26 26.061-8.956 50.122-28.176 73.554-9.023 10.994-25.383 28.58-63.165 54.228a15.384 15.384 0 0 1-8.653 2.673Z' fill='black' fill-rule='nonzero'/%3e%3c/svg%3e");
}

.mask-hexagon {
  -webkit-mask-image: url("data:image/svg+xml,%3csvg width='182' height='201' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M.3 65.486c0-9.196 6.687-20.063 14.211-25.078l61.86-35.946c8.36-5.016 20.899-5.016 29.258 0l61.86 35.946c8.36 5.015 14.211 15.882 14.211 25.078v71.055c0 9.196-6.687 20.063-14.211 25.079l-61.86 35.945c-8.36 4.18-20.899 4.18-29.258 0L14.51 161.62C6.151 157.44.3 145.737.3 136.54V65.486Z' fill='black' fill-rule='nonzero'/%3e%3c/svg%3e");
  mask-image: url("data:image/svg+xml,%3csvg width='182' height='201' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M.3 65.486c0-9.196 6.687-20.063 14.211-25.078l61.86-35.946c8.36-5.016 20.899-5.016 29.258 0l61.86 35.946c8.36 5.015 14.211 15.882 14.211 25.078v71.055c0 9.196-6.687 20.063-14.211 25.079l-61.86 35.945c-8.36 4.18-20.899 4.18-29.258 0L14.51 161.62C6.151 157.44.3 145.737.3 136.54V65.486Z' fill='black' fill-rule='nonzero'/%3e%3c/svg%3e");
}

.mask-hexagon-2 {
  -webkit-mask-image: url("data:image/svg+xml,%3csvg width='200' height='182' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M64.786 181.4c-9.196 0-20.063-6.687-25.079-14.21L3.762 105.33c-5.016-8.36-5.016-20.9 0-29.259l35.945-61.86C44.723 5.851 55.59 0 64.786 0h71.055c9.196 0 20.063 6.688 25.079 14.211l35.945 61.86c4.18 8.36 4.18 20.899 0 29.258l-35.945 61.86c-4.18 8.36-15.883 14.211-25.079 14.211H64.786Z' fill='black' fill-rule='nonzero'/%3e%3c/svg%3e");
  mask-image: url("data:image/svg+xml,%3csvg width='200' height='182' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M64.786 181.4c-9.196 0-20.063-6.687-25.079-14.21L3.762 105.33c-5.016-8.36-5.016-20.9 0-29.259l35.945-61.86C44.723 5.851 55.59 0 64.786 0h71.055c9.196 0 20.063 6.688 25.079 14.211l35.945 61.86c4.18 8.36 4.18 20.899 0 29.258l-35.945 61.86c-4.18 8.36-15.883 14.211-25.079 14.211H64.786Z' fill='black' fill-rule='nonzero'/%3e%3c/svg%3e");
}

.mask-circle {
  -webkit-mask-image: url("data:image/svg+xml,%3csvg width='200' height='200' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle fill='black' cx='100' cy='100' r='100' fill-rule='evenodd'/%3e%3c/svg%3e");
  mask-image: url("data:image/svg+xml,%3csvg width='200' height='200' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle fill='black' cx='100' cy='100' r='100' fill-rule='evenodd'/%3e%3c/svg%3e");
}

.mask-parallelogram {
  -webkit-mask-image: url("data:image/svg+xml,%3csvg width='200' height='154' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='black' d='M46.154 0H200l-46.154 153.846H0z' fill-rule='evenodd'/%3e%3c/svg%3e");
  mask-image: url("data:image/svg+xml,%3csvg width='200' height='154' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='black' d='M46.154 0H200l-46.154 153.846H0z' fill-rule='evenodd'/%3e%3c/svg%3e");
}

.mask-parallelogram-2 {
  -webkit-mask-image: url("data:image/svg+xml,%3csvg width='200' height='154' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='black' d='M153.846 0H0l46.154 153.846H200z' fill-rule='evenodd'/%3e%3c/svg%3e");
  mask-image: url("data:image/svg+xml,%3csvg width='200' height='154' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='black' d='M153.846 0H0l46.154 153.846H200z' fill-rule='evenodd'/%3e%3c/svg%3e");
}

.mask-parallelogram-3 {
  -webkit-mask-image: url("data:image/svg+xml,%3csvg width='154' height='201' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='black' d='M.077 47.077v153.846l153.846-46.154V.923z' fill-rule='evenodd'/%3e%3c/svg%3e");
  mask-image: url("data:image/svg+xml,%3csvg width='154' height='201' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='black' d='M.077 47.077v153.846l153.846-46.154V.923z' fill-rule='evenodd'/%3e%3c/svg%3e");
}

.mask-parallelogram-4 {
  -webkit-mask-image: url("data:image/svg+xml,%3csvg width='154' height='201' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='black' d='M153.923 47.077v153.846L.077 154.77V.923z' fill-rule='evenodd'/%3e%3c/svg%3e");
  mask-image: url("data:image/svg+xml,%3csvg width='154' height='201' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='black' d='M153.923 47.077v153.846L.077 154.77V.923z' fill-rule='evenodd'/%3e%3c/svg%3e");
}

.mask-pentagon {
  -webkit-mask-image: url("data:image/svg+xml,%3csvg width='192' height='181' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='black' d='m96 0 95.106 69.098-36.327 111.804H37.22L.894 69.098z' fill-rule='evenodd'/%3e%3c/svg%3e");
  mask-image: url("data:image/svg+xml,%3csvg width='192' height='181' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='black' d='m96 0 95.106 69.098-36.327 111.804H37.22L.894 69.098z' fill-rule='evenodd'/%3e%3c/svg%3e");
}

.mask-square {
  -webkit-mask-image: url("data:image/svg+xml,%3csvg width='200' height='200' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='black' d='M0 0h200v200H0z' fill-rule='evenodd'/%3e%3c/svg%3e");
  mask-image: url("data:image/svg+xml,%3csvg width='200' height='200' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='black' d='M0 0h200v200H0z' fill-rule='evenodd'/%3e%3c/svg%3e");
}

.mask-star {
  -webkit-mask-image: url("data:image/svg+xml,%3csvg width='192' height='180' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='black' d='m96 137.263-58.779 42.024 22.163-68.389L.894 68.481l72.476-.243L96 0l22.63 68.238 72.476.243-58.49 42.417 22.163 68.389z' fill-rule='evenodd'/%3e%3c/svg%3e");
  mask-image: url("data:image/svg+xml,%3csvg width='192' height='180' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='black' d='m96 137.263-58.779 42.024 22.163-68.389L.894 68.481l72.476-.243L96 0l22.63 68.238 72.476.243-58.49 42.417 22.163 68.389z' fill-rule='evenodd'/%3e%3c/svg%3e");
}

.mask-star-2 {
  -webkit-mask-image: url("data:image/svg+xml,%3csvg width='192' height='180' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='black' d='m96 153.044-58.779 26.243 7.02-63.513L.894 68.481l63.117-13.01L96 0l31.989 55.472 63.117 13.01-43.347 47.292 7.02 63.513z' fill-rule='evenodd'/%3e%3c/svg%3e");
  mask-image: url("data:image/svg+xml,%3csvg width='192' height='180' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='black' d='m96 153.044-58.779 26.243 7.02-63.513L.894 68.481l63.117-13.01L96 0l31.989 55.472 63.117 13.01-43.347 47.292 7.02 63.513z' fill-rule='evenodd'/%3e%3c/svg%3e");
}

.mask-triangle {
  -webkit-mask-image: url("data:image/svg+xml,%3csvg width='174' height='149' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='black' d='m87 148.476-86.603.185L43.86 74.423 87 0l43.14 74.423 43.463 74.238z' fill-rule='evenodd'/%3e%3c/svg%3e");
  mask-image: url("data:image/svg+xml,%3csvg width='174' height='149' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='black' d='m87 148.476-86.603.185L43.86 74.423 87 0l43.14 74.423 43.463 74.238z' fill-rule='evenodd'/%3e%3c/svg%3e");
}

.mask-triangle-2 {
  -webkit-mask-image: url("data:image/svg+xml,%3csvg width='174' height='150' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='black' d='m87 .738 86.603-.184-43.463 74.238L87 149.214 43.86 74.792.397.554z' fill-rule='evenodd'/%3e%3c/svg%3e");
  mask-image: url("data:image/svg+xml,%3csvg width='174' height='150' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='black' d='m87 .738 86.603-.184-43.463 74.238L87 149.214 43.86 74.792.397.554z' fill-rule='evenodd'/%3e%3c/svg%3e");
}

.mask-triangle-3 {
  -webkit-mask-image: url("data:image/svg+xml,%3csvg width='150' height='174' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='black' d='m149.369 87.107.185 86.603-74.239-43.463L.893 87.107l74.422-43.14L149.554.505z' fill-rule='evenodd'/%3e%3c/svg%3e");
  mask-image: url("data:image/svg+xml,%3csvg width='150' height='174' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='black' d='m149.369 87.107.185 86.603-74.239-43.463L.893 87.107l74.422-43.14L149.554.505z' fill-rule='evenodd'/%3e%3c/svg%3e");
}

.mask-triangle-4 {
  -webkit-mask-image: url("data:image/svg+xml,%3csvg width='150' height='174' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='black' d='M.631 87.107.446.505l74.239 43.462 74.422 43.14-74.422 43.14L.446 173.71z' fill-rule='evenodd'/%3e%3c/svg%3e");
  mask-image: url("data:image/svg+xml,%3csvg width='150' height='174' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='black' d='M.631 87.107.446.505l74.239 43.462 74.422 43.14-74.422 43.14L.446 173.71z' fill-rule='evenodd'/%3e%3c/svg%3e");
}

:where(.menu li:empty) {
  background-color: hsl(var(--bc) / .1);
  height: 1px;
  margin: .5rem 1rem;
}

.menu :where(li ul):before {
  background-color: hsl(var(--bc) / .1);
  content: "";
  width: 1px;
  position: absolute;
  top: .75rem;
  bottom: .75rem;
  left: 0;
}

.menu :where(li:not(.menu-title) > :not(ul):not(details):not(.menu-title)), .menu :where(li:not(.menu-title) > details > summary:not(.menu-title)) {
  text-align: left;
  border-radius: var(--rounded-btn, .5rem);
  text-wrap: balance;
  padding: .5rem 1rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

:where(.menu li:not(.menu-title):not(.disabled) > :not(ul):not(details):not(.menu-title)):not(summary):not(.active).focus, :where(.menu li:not(.menu-title):not(.disabled) > :not(ul):not(details):not(.menu-title)):not(summary):not(.active):focus, :where(.menu li:not(.menu-title):not(.disabled) > :not(ul):not(details):not(.menu-title)):is(summary):not(.active):focus-visible, :where(.menu li:not(.menu-title):not(.disabled) > details > summary:not(.menu-title)):not(summary):not(.active).focus, :where(.menu li:not(.menu-title):not(.disabled) > details > summary:not(.menu-title)):not(summary):not(.active):focus, :where(.menu li:not(.menu-title):not(.disabled) > details > summary:not(.menu-title)):is(summary):not(.active):focus-visible {
  cursor: pointer;
  background-color: hsl(var(--bc) / .1);
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity));
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.menu li > :not(ul):not(.menu-title):not(details):active, .menu li > :not(ul):not(.menu-title):not(details).active, .menu li > details > summary:active {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--n) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--nc) / var(--tw-text-opacity));
}

.menu :where(li > details > summary)::-webkit-details-marker {
  display: none;
}

.menu :where(li > details > summary):after, .menu :where(li > .menu-dropdown-toggle):after {
  content: "";
  transform-origin: 75% 75%;
  pointer-events: none;
  justify-self: end;
  width: .5rem;
  height: .5rem;
  margin-top: -.5rem;
  transition-property: transform, margin-top;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: block;
  transform: rotate(45deg);
  box-shadow: 2px 2px;
}

.menu :where(li > details[open] > summary):after, .menu :where(li > .menu-dropdown-toggle.menu-dropdown-show):after {
  margin-top: 0;
  transform: rotate(225deg);
}

.menu-title {
  color: hsl(var(--bc) / .4);
  padding: .5rem 1rem;
  font-size: .875rem;
  font-weight: 700;
  line-height: 1.25rem;
}

.mockup-code:before {
  content: "";
  opacity: .3;
  border-radius: 9999px;
  width: .75rem;
  height: .75rem;
  margin-bottom: 1rem;
  display: block;
  box-shadow: 1.4em 0, 2.8em 0, 4.2em 0;
}

.mockup-code pre {
  padding-right: 1.25rem;
}

.mockup-code pre:before {
  content: "";
  margin-right: 2ch;
}

.mockup-window:before {
  content: "";
  aspect-ratio: 1;
  opacity: .3;
  border-radius: 9999px;
  height: .75rem;
  margin-bottom: 1rem;
  display: block;
  box-shadow: 1.4em 0, 2.8em 0, 4.2em 0;
}

.mockup-phone {
  background-color: #000;
  border: 4px solid #444;
  border-radius: 50px;
  margin: 0 auto;
  padding: 10px;
  display: inline-block;
  overflow: hidden;
}

.mockup-phone .camera {
  z-index: 11;
  background: #000;
  border-bottom-right-radius: 17px;
  border-bottom-left-radius: 17px;
  width: 150px;
  height: 25px;
  margin: 0 auto;
  position: relative;
  top: 0;
  left: 0;
}

.mockup-phone .camera:before {
  content: "";
  background-color: #0c0b0e;
  border-radius: 5px;
  width: 50px;
  height: 4px;
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mockup-phone .camera:after {
  content: "";
  background-color: #0f0b25;
  border-radius: 5px;
  width: 8px;
  height: 8px;
  position: absolute;
  top: 20%;
  left: 70%;
}

.mockup-phone .display {
  border-radius: 40px;
  margin-top: -25px;
  overflow: hidden;
}

.mockup-browser .mockup-browser-toolbar {
  align-items: center;
  width: 100%;
  margin-top: .75rem;
  margin-bottom: .75rem;
  padding-right: 1.4em;
  display: inline-flex;
}

.mockup-browser .mockup-browser-toolbar:before {
  content: "";
  aspect-ratio: 1;
  opacity: .3;
  border-radius: 9999px;
  height: .75rem;
  margin-right: 4.8rem;
  display: inline-block;
  box-shadow: 1.4em 0, 2.8em 0, 4.2em 0;
}

.mockup-browser .mockup-browser-toolbar .input {
  text-overflow: ellipsis;
  white-space: nowrap;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2) / var(--tw-bg-opacity));
  width: 24rem;
  height: 1.75rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2rem;
  display: block;
  position: relative;
  overflow: hidden;
}

.mockup-browser .mockup-browser-toolbar .input:before {
  content: "";
  aspect-ratio: 1;
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  opacity: .6;
  border-width: 2px;
  border-color: currentColor;
  border-radius: 9999px;
  height: .75rem;
  position: absolute;
  top: 50%;
  left: .5rem;
}

.mockup-browser .mockup-browser-toolbar .input:after {
  content: "";
  --tw-translate-y: 25%;
  --tw-rotate: -45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  opacity: .6;
  border-width: 1px;
  border-color: currentColor;
  border-radius: 9999px;
  height: .5rem;
  position: absolute;
  top: 50%;
  left: 1.25rem;
}

.modal:not(dialog:not(.modal-open)), .modal::backdrop {
  background-color: #0000004d;
  animation: .2s ease-out modal-pop;
}

.modal-backdrop {
  z-index: -1;
  color: #0000;
  grid-row-start: 1;
  grid-column-start: 1;
  place-self: stretch stretch;
  display: grid;
}

.modal-open .modal-box, .modal-toggle:checked + .modal .modal-box, .modal:target .modal-box, .modal[open] .modal-box {
  --tw-translate-y: 0px;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.modal-action > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
}

@keyframes modal-pop {
  0% {
    opacity: 0;
  }
}

.progress::-moz-progress-bar {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--bc) / var(--tw-bg-opacity));
}

.progress-primary::-moz-progress-bar {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--p) / var(--tw-bg-opacity));
}

.progress-secondary::-moz-progress-bar {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--s) / var(--tw-bg-opacity));
}

.progress-accent::-moz-progress-bar {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--a) / var(--tw-bg-opacity));
}

.progress-info::-moz-progress-bar {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--in) / var(--tw-bg-opacity));
}

.progress-success::-moz-progress-bar {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--su) / var(--tw-bg-opacity));
}

.progress-warning::-moz-progress-bar {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--wa) / var(--tw-bg-opacity));
}

.progress-error::-moz-progress-bar {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--er) / var(--tw-bg-opacity));
}

.progress:indeterminate {
  --progress-color: hsl(var(--bc));
  background-image: repeating-linear-gradient(90deg, var(--progress-color) -1%, var(--progress-color) 10%, transparent 10%, transparent 90%);
  background-position-x: 15%;
  background-size: 200%;
  animation: 5s ease-in-out infinite progress-loading;
}

.progress-primary:indeterminate {
  --progress-color: hsl(var(--p));
}

.progress-secondary:indeterminate {
  --progress-color: hsl(var(--s));
}

.progress-accent:indeterminate {
  --progress-color: hsl(var(--a));
}

.progress-info:indeterminate {
  --progress-color: hsl(var(--in));
}

.progress-success:indeterminate {
  --progress-color: hsl(var(--su));
}

.progress-warning:indeterminate {
  --progress-color: hsl(var(--wa));
}

.progress-error:indeterminate {
  --progress-color: hsl(var(--er));
}

.progress::-webkit-progress-bar {
  border-radius: var(--rounded-box, 1rem);
  background-color: #0000;
}

.progress::-webkit-progress-value {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--bc) / var(--tw-bg-opacity));
  border-radius: var(--rounded-box, 1rem);
}

.progress-primary::-webkit-progress-value {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--p) / var(--tw-bg-opacity));
}

.progress-secondary::-webkit-progress-value {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--s) / var(--tw-bg-opacity));
}

.progress-accent::-webkit-progress-value {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--a) / var(--tw-bg-opacity));
}

.progress-info::-webkit-progress-value {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--in) / var(--tw-bg-opacity));
}

.progress-success::-webkit-progress-value {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--su) / var(--tw-bg-opacity));
}

.progress-warning::-webkit-progress-value {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--wa) / var(--tw-bg-opacity));
}

.progress-error::-webkit-progress-value {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--er) / var(--tw-bg-opacity));
}

.progress:indeterminate::-moz-progress-bar {
  background-color: #0000;
  background-image: repeating-linear-gradient(90deg, var(--progress-color) -1%, var(--progress-color) 10%, transparent 10%, transparent 90%);
  background-position-x: 15%;
  background-size: 200%;
  animation: 5s ease-in-out infinite progress-loading;
}

@keyframes progress-loading {
  50% {
    background-position-x: -115%;
  }
}

.radio:focus-visible {
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
  outline-color: hsl(var(--bc) / 1);
}

.radio:checked, .radio[aria-checked="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--bc) / var(--tw-bg-opacity));
  animation: radiomark var(--animation-input, .2s) ease-out;
  box-shadow: 0 0 0 4px hsl(var(--b1)) inset, 0 0 0 4px hsl(var(--b1)) inset;
}

.radio-primary {
  --chkbg: var(--p);
  --tw-border-opacity: 1;
  border-color: hsl(var(--p) / var(--tw-border-opacity));
}

.radio-primary:focus-visible {
  outline-color: hsl(var(--p) / 1);
}

.radio-primary:checked, .radio-primary[aria-checked="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--p) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--p) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--pc) / var(--tw-text-opacity));
}

.radio-secondary {
  --chkbg: var(--s);
  --tw-border-opacity: 1;
  border-color: hsl(var(--s) / var(--tw-border-opacity));
}

.radio-secondary:focus-visible {
  outline-color: hsl(var(--s) / 1);
}

.radio-secondary:checked, .radio-secondary[aria-checked="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--s) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--s) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--sc) / var(--tw-text-opacity));
}

.radio-accent {
  --chkbg: var(--a);
  --tw-border-opacity: 1;
  border-color: hsl(var(--a) / var(--tw-border-opacity));
}

.radio-accent:focus-visible {
  outline-color: hsl(var(--a) / 1);
}

.radio-accent:checked, .radio-accent[aria-checked="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--a) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--a) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--ac) / var(--tw-text-opacity));
}

.radio-success {
  --chkbg: var(--su);
  --tw-border-opacity: 1;
  border-color: hsl(var(--su) / var(--tw-border-opacity));
}

.radio-success:focus-visible {
  outline-color: hsl(var(--su) / 1);
}

.radio-success:checked, .radio-success[aria-checked="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--su) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--su) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--suc) / var(--tw-text-opacity));
}

.radio-warning {
  --chkbg: var(--wa);
  --tw-border-opacity: 1;
  border-color: hsl(var(--wa) / var(--tw-border-opacity));
}

.radio-warning:focus-visible {
  outline-color: hsl(var(--wa) / 1);
}

.radio-warning:checked, .radio-warning[aria-checked="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--wa) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--wa) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--wac) / var(--tw-text-opacity));
}

.radio-info {
  --chkbg: var(--in);
  --tw-border-opacity: 1;
  border-color: hsl(var(--in) / var(--tw-border-opacity));
}

.radio-info:focus-visible {
  outline-color: hsl(var(--in) / 1);
}

.radio-info:checked, .radio-info[aria-checked="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--in) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--in) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--inc) / var(--tw-text-opacity));
}

.radio-error {
  --chkbg: var(--er);
  --tw-border-opacity: 1;
  border-color: hsl(var(--er) / var(--tw-border-opacity));
}

.radio-error:focus-visible {
  outline-color: hsl(var(--er) / 1);
}

.radio-error:checked, .radio-error[aria-checked="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--er) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--er) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--erc) / var(--tw-text-opacity));
}

.radio:disabled {
  cursor: not-allowed;
  opacity: .2;
}

@keyframes radiomark {
  0% {
    box-shadow: 0 0 0 12px hsl(var(--b1)) inset, 0 0 0 12px hsl(var(--b1)) inset;
  }

  50% {
    box-shadow: 0 0 0 3px hsl(var(--b1)) inset, 0 0 0 3px hsl(var(--b1)) inset;
  }

  100% {
    box-shadow: 0 0 0 4px hsl(var(--b1)) inset, 0 0 0 4px hsl(var(--b1)) inset;
  }
}

.radio-mark {
  display: none;
}

.range:focus-visible::-webkit-slider-thumb {
  --focus-shadow: 0 0 0 6px hsl(var(--b1)) inset, 0 0 0 2rem hsl(var(--range-shdw)) inset;
}

.range:focus-visible::-moz-range-thumb {
  --focus-shadow: 0 0 0 6px hsl(var(--b1)) inset, 0 0 0 2rem hsl(var(--range-shdw)) inset;
}

.range::-webkit-slider-runnable-track {
  background-color: hsl(var(--bc) / .1);
  border-radius: var(--rounded-box, 1rem);
  width: 100%;
  height: .5rem;
}

.range::-moz-range-track {
  background-color: hsl(var(--bc) / .1);
  border-radius: var(--rounded-box, 1rem);
  width: 100%;
  height: .5rem;
}

.range::-webkit-slider-thumb {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b1) / var(--tw-bg-opacity));
  border-radius: var(--rounded-box, 1rem);
  appearance: none;
  color: hsl(var(--range-shdw));
  --filler-size: 100rem;
  --filler-offset: .6rem;
  box-shadow: 0 0 0 3px hsl(var(--range-shdw)) inset, var(--focus-shadow, 0 0), calc(var(--filler-size) * -1 - var(--filler-offset)) 0 0 var(--filler-size);
  border-style: none;
  width: 1.5rem;
  height: 1.5rem;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.range::-moz-range-thumb {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b1) / var(--tw-bg-opacity));
  border-radius: var(--rounded-box, 1rem);
  color: hsl(var(--range-shdw));
  --filler-size: 100rem;
  --filler-offset: .5rem;
  box-shadow: 0 0 0 3px hsl(var(--range-shdw)) inset, var(--focus-shadow, 0 0), calc(var(--filler-size) * -1 - var(--filler-offset)) 0 0 var(--filler-size);
  border-style: none;
  width: 1.5rem;
  height: 1.5rem;
  position: relative;
  top: 50%;
}

.range-primary {
  --range-shdw: var(--p);
}

.range-secondary {
  --range-shdw: var(--s);
}

.range-accent {
  --range-shdw: var(--a);
}

.range-success {
  --range-shdw: var(--su);
}

.range-warning {
  --range-shdw: var(--wa);
}

.range-info {
  --range-shdw: var(--in);
}

.range-error {
  --range-shdw: var(--er);
}

.rating input {
  appearance: none;
}

.rating .rating-hidden {
  background-color: #0000;
  width: .5rem;
}

.rating input:checked ~ input, .rating input[aria-checked="true"] ~ input {
  --tw-bg-opacity: .2;
}

.rating input:focus-visible {
  transition-property: transform;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
  transform: translateY(-.125em);
}

.rating input:active:focus {
  animation: none;
  transform: translateY(-.125em);
}

.rating-half :where(input:not(.rating-hidden)) {
  width: .75rem;
}

@keyframes rating-pop {
  0% {
    transform: translateY(-.125em);
  }

  40% {
    transform: translateY(-.125em);
  }

  100% {
    transform: translateY(0);
  }
}

.select-bordered {
  --tw-border-opacity: .2;
}

.select:focus {
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
  outline-color: hsl(var(--bc) / .2);
}

.select-ghost {
  --tw-bg-opacity: .05;
}

.select-ghost:focus {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity));
}

.select-primary {
  --tw-border-opacity: 1;
  border-color: hsl(var(--p) / var(--tw-border-opacity));
}

.select-primary:focus {
  outline-color: hsl(var(--p) / 1);
}

.select-secondary {
  --tw-border-opacity: 1;
  border-color: hsl(var(--s) / var(--tw-border-opacity));
}

.select-secondary:focus {
  outline-color: hsl(var(--s) / 1);
}

.select-accent {
  --tw-border-opacity: 1;
  border-color: hsl(var(--a) / var(--tw-border-opacity));
}

.select-accent:focus {
  outline-color: hsl(var(--a) / 1);
}

.select-info {
  --tw-border-opacity: 1;
  border-color: hsl(var(--in) / var(--tw-border-opacity));
}

.select-info:focus {
  outline-color: hsl(var(--in) / 1);
}

.select-success {
  --tw-border-opacity: 1;
  border-color: hsl(var(--su) / var(--tw-border-opacity));
}

.select-success:focus {
  outline-color: hsl(var(--su) / 1);
}

.select-warning {
  --tw-border-opacity: 1;
  border-color: hsl(var(--wa) / var(--tw-border-opacity));
}

.select-warning:focus {
  outline-color: hsl(var(--wa) / 1);
}

.select-error {
  --tw-border-opacity: 1;
  border-color: hsl(var(--er) / var(--tw-border-opacity));
}

.select-error:focus {
  outline-color: hsl(var(--er) / 1);
}

.select-disabled, .select:disabled, .select[disabled] {
  cursor: not-allowed;
  --tw-border-opacity: 1;
  border-color: hsl(var(--b2) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2) / var(--tw-bg-opacity));
  --tw-text-opacity: .2;
}

.select-disabled::placeholder, .select:disabled::placeholder, .select[disabled]::placeholder {
  color: hsl(var(--bc) / var(--tw-placeholder-opacity));
  --tw-placeholder-opacity: .2;
}

.select-multiple, .select[multiple], .select[size].select:not([size="1"]) {
  background-image: none;
  padding-right: 1rem;
}

[dir="rtl"] .select {
  background-position: 12px calc(1px + 50%), 16px calc(1px + 50%);
}

:where(.stats) > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(1px * var(--tw-divide-x-reverse));
  border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
  --tw-divide-y-reverse: 0;
  border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
}

.steps .step:before {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b3) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity));
  content: "";
  grid-row-start: 1;
  grid-column-start: 1;
  width: 100%;
  height: .5rem;
  margin-left: -100%;
  top: 0;
}

.steps .step:after {
  content: counter(step);
  counter-increment: step;
  z-index: 1;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b3) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity));
  border-radius: 9999px;
  grid-row-start: 1;
  grid-column-start: 1;
  place-self: center;
  place-items: center;
  width: 2rem;
  height: 2rem;
  display: grid;
  position: relative;
}

.steps .step:first-child:before {
  content: none;
}

.steps .step[data-content]:after {
  content: attr(data-content);
}

.steps .step-neutral + .step-neutral:before, .steps .step-neutral:after {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--n) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--nc) / var(--tw-text-opacity));
}

.steps .step-primary + .step-primary:before, .steps .step-primary:after {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--p) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--pc) / var(--tw-text-opacity));
}

.steps .step-secondary + .step-secondary:before, .steps .step-secondary:after {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--s) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--sc) / var(--tw-text-opacity));
}

.steps .step-accent + .step-accent:before, .steps .step-accent:after {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--a) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--ac) / var(--tw-text-opacity));
}

.steps .step-info + .step-info:before {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--in) / var(--tw-bg-opacity));
}

.steps .step-info:after {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--in) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--inc) / var(--tw-text-opacity));
}

.steps .step-success + .step-success:before {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--su) / var(--tw-bg-opacity));
}

.steps .step-success:after {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--su) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--suc) / var(--tw-text-opacity));
}

.steps .step-warning + .step-warning:before {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--wa) / var(--tw-bg-opacity));
}

.steps .step-warning:after {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--wa) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--wac) / var(--tw-text-opacity));
}

.steps .step-error + .step-error:before {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--er) / var(--tw-bg-opacity));
}

.steps .step-error:after {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--er) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--erc) / var(--tw-text-opacity));
}

.swap-rotate .swap-on, .swap-rotate .swap-indeterminate, .swap-rotate input:indeterminate ~ .swap-on {
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.swap-rotate input:checked ~ .swap-off, .swap-rotate.swap-active .swap-off, .swap-rotate input:indeterminate ~ .swap-off {
  --tw-rotate: -45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.swap-rotate input:checked ~ .swap-on, .swap-rotate.swap-active .swap-on, .swap-rotate input:indeterminate ~ .swap-indeterminate {
  --tw-rotate: 0deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.swap-flip {
  transform-style: preserve-3d;
  perspective: 16em;
}

.swap-flip .swap-on, .swap-flip .swap-indeterminate, .swap-flip input:indeterminate ~ .swap-on {
  backface-visibility: hidden;
  opacity: 1;
  transform: rotateY(180deg);
}

.swap-flip input:checked ~ .swap-off, .swap-flip.swap-active .swap-off, .swap-flip input:indeterminate ~ .swap-off {
  backface-visibility: hidden;
  opacity: 1;
  transform: rotateY(-180deg);
}

.swap-flip input:checked ~ .swap-on, .swap-flip.swap-active .swap-on, .swap-flip input:indeterminate ~ .swap-indeterminate {
  transform: rotateY(0);
}

.tab.tab-active:not(.tab-disabled):not([disabled]) {
  border-color: hsl(var(--bc) / var(--tw-border-opacity));
  --tw-border-opacity: 1;
  --tw-text-opacity: 1;
}

.tab:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.tab:focus-visible {
  outline-offset: -3px;
  outline: 2px solid;
}

.tab:focus-visible.tab-lifted {
  border-bottom-right-radius: var(--tab-radius, .5rem);
  border-bottom-left-radius: var(--tab-radius, .5rem);
}

.tab-disabled, .tab[disabled] {
  cursor: not-allowed;
  color: hsl(var(--bc) / var(--tw-text-opacity));
  --tw-text-opacity: .2;
}

.tab-bordered {
  border-color: hsl(var(--bc) / var(--tw-border-opacity));
  --tw-border-opacity: .2;
  border-style: solid;
  border-bottom-width: calc(var(--tab-border, 1px)  + 1px);
}

.tab-lifted {
  border: var(--tab-border, 1px) solid transparent;
  border-width: 0 0 var(--tab-border, 1px) 0;
  border-top-left-radius: var(--tab-radius, .5rem);
  border-top-right-radius: var(--tab-radius, .5rem);
  border-bottom-color: var(--tab-border-color);
  padding-left: var(--tab-padding, 1rem);
  padding-right: var(--tab-padding, 1rem);
  padding-top: var(--tab-border, 1px);
}

.tab-lifted.tab-active:not(.tab-disabled):not([disabled]) {
  background-color: var(--tab-bg);
  border-width: var(--tab-border, 1px) var(--tab-border, 1px) 0 var(--tab-border, 1px);
  border-left-color: var(--tab-border-color);
  border-right-color: var(--tab-border-color);
  border-top-color: var(--tab-border-color);
  padding-left: calc(var(--tab-padding, 1rem)  - var(--tab-border, 1px));
  padding-right: calc(var(--tab-padding, 1rem)  - var(--tab-border, 1px));
  padding-bottom: var(--tab-border, 1px);
  padding-top: 0;
}

.tab-lifted.tab-active:not(.tab-disabled):not([disabled]):before, .tab-lifted.tab-active:not(.tab-disabled):not([disabled]):after {
  z-index: 1;
  content: "";
  width: var(--tab-radius, .5rem);
  height: var(--tab-radius, .5rem);
  --tab-grad: calc(68% - var(--tab-border, 1px));
  --tab-corner-bg: radial-gradient(circle at var(--circle-pos), transparent var(--tab-grad), var(--tab-border-color) calc(var(--tab-grad)  + .3px), var(--tab-border-color) calc(var(--tab-grad)  + var(--tab-border, 1px)), var(--tab-bg) calc(var(--tab-grad)  + var(--tab-border, 1px)  + .3px));
  display: block;
  position: absolute;
  bottom: 0;
}

.tab-lifted.tab-active:not(.tab-disabled):not([disabled]):before {
  left: calc(var(--tab-radius, .5rem) * -1);
  --circle-pos: top left;
  background-image: var(--tab-corner-bg);
}

[dir="rtl"] .tab-lifted.tab-active:not(.tab-disabled):not([disabled]):before {
  --circle-pos: top right;
}

.tab-lifted.tab-active:not(.tab-disabled):not([disabled]):after {
  right: calc(var(--tab-radius, .5rem) * -1);
  --circle-pos: top right;
  background-image: var(--tab-corner-bg);
}

[dir="rtl"] .tab-lifted.tab-active:not(.tab-disabled):not([disabled]):after {
  --circle-pos: top left;
}

.tab-lifted.tab-active:not(.tab-disabled):not([disabled]):first-child:before, .tab-lifted.tab-active:not(.tab-disabled):not([disabled]):last-child:after, .tab-lifted.tab-active:not(.tab-disabled):not([disabled]) + .tab-lifted.tab-active:not(.tab-disabled):not([disabled]):before {
  background: none;
}

.tabs-boxed {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2) / var(--tw-bg-opacity));
  border-radius: var(--rounded-btn, .5rem);
  padding: .25rem;
}

.tabs-boxed .tab {
  border-radius: var(--rounded-btn, .5rem);
}

.tabs-boxed .tab-active:not(.tab-disabled):not([disabled]) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--p) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--pc) / var(--tw-text-opacity));
}

.table :where(th, td) {
  vertical-align: middle;
  padding: .75rem 1rem;
}

.table tr.active, .table tr.active:nth-child(2n), .table-zebra tbody tr:nth-child(2n) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2) / var(--tw-bg-opacity));
}

.table-zebra tr.active, .table-zebra tr.active:nth-child(2n), .table-zebra-zebra tbody tr:nth-child(2n) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b3) / var(--tw-bg-opacity));
}

.table :where(thead, tbody) :where(tr:not(:last-child)), .table :where(thead, tbody) :where(tr:first-child:last-child) {
  --tw-border-opacity: 1;
  border-bottom-width: 1px;
  border-bottom-color: hsl(var(--b2) / var(--tw-border-opacity));
}

.table :where(thead, tfoot) {
  white-space: nowrap;
  color: hsl(var(--bc) / .6);
  font-size: .75rem;
  font-weight: 700;
  line-height: 1rem;
}

.textarea-bordered {
  --tw-border-opacity: .2;
}

.textarea:focus {
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
  outline-color: hsl(var(--bc) / .2);
}

.textarea-ghost {
  --tw-bg-opacity: .05;
}

.textarea-ghost:focus {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity));
  box-shadow: none;
}

.textarea-primary {
  --tw-border-opacity: 1;
  border-color: hsl(var(--p) / var(--tw-border-opacity));
}

.textarea-primary:focus {
  outline-color: hsl(var(--p) / 1);
}

.textarea-secondary {
  --tw-border-opacity: 1;
  border-color: hsl(var(--s) / var(--tw-border-opacity));
}

.textarea-secondary:focus {
  outline-color: hsl(var(--s) / 1);
}

.textarea-accent {
  --tw-border-opacity: 1;
  border-color: hsl(var(--a) / var(--tw-border-opacity));
}

.textarea-accent:focus {
  outline-color: hsl(var(--a) / 1);
}

.textarea-info {
  --tw-border-opacity: 1;
  border-color: hsl(var(--in) / var(--tw-border-opacity));
}

.textarea-info:focus {
  outline-color: hsl(var(--in) / 1);
}

.textarea-success {
  --tw-border-opacity: 1;
  border-color: hsl(var(--su) / var(--tw-border-opacity));
}

.textarea-success:focus {
  outline-color: hsl(var(--su) / 1);
}

.textarea-warning {
  --tw-border-opacity: 1;
  border-color: hsl(var(--wa) / var(--tw-border-opacity));
}

.textarea-warning:focus {
  outline-color: hsl(var(--wa) / 1);
}

.textarea-error {
  --tw-border-opacity: 1;
  border-color: hsl(var(--er) / var(--tw-border-opacity));
}

.textarea-error:focus {
  outline-color: hsl(var(--er) / 1);
}

.textarea-disabled, .textarea:disabled, .textarea[disabled] {
  cursor: not-allowed;
  --tw-border-opacity: 1;
  border-color: hsl(var(--b2) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2) / var(--tw-bg-opacity));
  --tw-text-opacity: .2;
}

.textarea-disabled::placeholder, .textarea:disabled::placeholder, .textarea[disabled]::placeholder {
  color: hsl(var(--bc) / var(--tw-placeholder-opacity));
  --tw-placeholder-opacity: .2;
}

.toast > * {
  animation: .25s ease-out toast-pop;
}

@keyframes toast-pop {
  0% {
    opacity: 0;
    transform: scale(.9);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

[dir="rtl"] .toggle {
  --handleoffsetcalculator: calc(var(--handleoffset) * 1);
}

.toggle:focus-visible {
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
  outline-color: hsl(var(--bc) / .2);
}

.toggle:checked, .toggle[checked="true"], .toggle[aria-checked="true"] {
  --handleoffsetcalculator: var(--handleoffset);
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
}

[dir="rtl"] .toggle:checked, [dir="rtl"] .toggle[checked="true"], [dir="rtl"] .toggle[aria-checked="true"] {
  --handleoffsetcalculator: calc(var(--handleoffset) * -1);
}

.toggle:indeterminate {
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  box-shadow: calc(var(--handleoffset) / 2) 0 0 2px var(--tglbg) inset, calc(var(--handleoffset) / -2) 0 0 2px var(--tglbg) inset, 0 0 0 2px var(--tglbg) inset;
}

[dir="rtl"] .toggle:indeterminate {
  box-shadow: calc(var(--handleoffset) / 2) 0 0 2px var(--tglbg) inset, calc(var(--handleoffset) / -2) 0 0 2px var(--tglbg) inset, 0 0 0 2px var(--tglbg) inset;
}

.toggle-primary:focus-visible {
  outline-color: hsl(var(--p) / 1);
}

.toggle-primary:checked, .toggle-primary[checked="true"], .toggle-primary[aria-checked="true"] {
  border-color: hsl(var(--p) / var(--tw-border-opacity));
  --tw-border-opacity: .1;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--p) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--pc) / var(--tw-text-opacity));
}

.toggle-secondary:focus-visible {
  outline-color: hsl(var(--s) / 1);
}

.toggle-secondary:checked, .toggle-secondary[checked="true"], .toggle-secondary[aria-checked="true"] {
  border-color: hsl(var(--s) / var(--tw-border-opacity));
  --tw-border-opacity: .1;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--s) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--sc) / var(--tw-text-opacity));
}

.toggle-accent:focus-visible {
  outline-color: hsl(var(--a) / 1);
}

.toggle-accent:checked, .toggle-accent[checked="true"], .toggle-accent[aria-checked="true"] {
  border-color: hsl(var(--a) / var(--tw-border-opacity));
  --tw-border-opacity: .1;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--a) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--ac) / var(--tw-text-opacity));
}

.toggle-success:focus-visible {
  outline-color: hsl(var(--su) / 1);
}

.toggle-success:checked, .toggle-success[checked="true"], .toggle-success[aria-checked="true"] {
  border-color: hsl(var(--su) / var(--tw-border-opacity));
  --tw-border-opacity: .1;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--su) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--suc) / var(--tw-text-opacity));
}

.toggle-warning:focus-visible {
  outline-color: hsl(var(--wa) / 1);
}

.toggle-warning:checked, .toggle-warning[checked="true"], .toggle-warning[aria-checked="true"] {
  border-color: hsl(var(--wa) / var(--tw-border-opacity));
  --tw-border-opacity: .1;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--wa) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--wac) / var(--tw-text-opacity));
}

.toggle-info:focus-visible {
  outline-color: hsl(var(--in) / 1);
}

.toggle-info:checked, .toggle-info[checked="true"], .toggle-info[aria-checked="true"] {
  border-color: hsl(var(--in) / var(--tw-border-opacity));
  --tw-border-opacity: .1;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--in) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--inc) / var(--tw-text-opacity));
}

.toggle-error:focus-visible {
  outline-color: hsl(var(--er) / 1);
}

.toggle-error:checked, .toggle-error[checked="true"], .toggle-error[aria-checked="true"] {
  border-color: hsl(var(--er) / var(--tw-border-opacity));
  --tw-border-opacity: .1;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--er) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--erc) / var(--tw-text-opacity));
}

.toggle:disabled {
  cursor: not-allowed;
  --tw-border-opacity: 1;
  border-color: hsl(var(--bc) / var(--tw-border-opacity));
  opacity: .3;
  --togglehandleborder: 0 0 0 3px hsl(var(--bc)) inset, var(--handleoffsetcalculator) 0 0 3px hsl(var(--bc)) inset;
  background-color: #0000;
}

.toggle-mark {
  display: none;
}

.tooltip.tooltip-primary {
  --tooltip-color: hsl(var(--p));
  --tooltip-text-color: hsl(var(--pc));
}

.tooltip.tooltip-secondary {
  --tooltip-color: hsl(var(--s));
  --tooltip-text-color: hsl(var(--sc));
}

.tooltip.tooltip-accent {
  --tooltip-color: hsl(var(--a));
  --tooltip-text-color: hsl(var(--ac));
}

.tooltip.tooltip-info {
  --tooltip-color: hsl(var(--in));
  --tooltip-text-color: hsl(var(--inc));
}

.tooltip.tooltip-success {
  --tooltip-color: hsl(var(--su));
  --tooltip-text-color: hsl(var(--suc));
}

.tooltip.tooltip-warning {
  --tooltip-color: hsl(var(--wa));
  --tooltip-text-color: hsl(var(--wac));
}

.tooltip.tooltip-error {
  --tooltip-color: hsl(var(--er));
  --tooltip-text-color: hsl(var(--erc));
}

:root .prose {
  --tw-prose-body: hsl(var(--bc) / .8);
  --tw-prose-headings: hsl(var(--bc));
  --tw-prose-lead: hsl(var(--bc));
  --tw-prose-links: hsl(var(--bc));
  --tw-prose-bold: hsl(var(--bc));
  --tw-prose-counters: hsl(var(--bc));
  --tw-prose-bullets: hsl(var(--bc) / .5);
  --tw-prose-hr: hsl(var(--bc) / .2);
  --tw-prose-quotes: hsl(var(--bc));
  --tw-prose-quote-borders: hsl(var(--bc) / .2);
  --tw-prose-captions: hsl(var(--bc) / .5);
  --tw-prose-code: hsl(var(--bc));
  --tw-prose-pre-code: hsl(var(--nc));
  --tw-prose-pre-bg: hsl(var(--n));
  --tw-prose-th-borders: hsl(var(--bc) / .5);
  --tw-prose-td-borders: hsl(var(--bc) / .2);
}

.prose :where(code):not(:where([class~="not-prose"] *)) {
  border-radius: var(--rounded-badge);
  padding: 2px 8px;
}

.prose code:after, .prose code:before {
  content: none;
}

.prose pre code {
  border-radius: 0;
  padding: 0;
}

.prose :where(tbody tr, thead):not(:where([class~="not-prose"] *)) {
  border-bottom-color: hsl(var(--bc) / 20%);
}

.rounded-box {
  border-radius: var(--rounded-box, 1rem);
}

.rounded-t-box {
  border-top-left-radius: var(--rounded-box, 1rem);
  border-top-right-radius: var(--rounded-box, 1rem);
}

.rounded-b-box {
  border-bottom-left-radius: var(--rounded-box, 1rem);
  border-bottom-right-radius: var(--rounded-box, 1rem);
}

.rounded-l-box {
  border-top-left-radius: var(--rounded-box, 1rem);
  border-bottom-left-radius: var(--rounded-box, 1rem);
}

.rounded-r-box {
  border-top-right-radius: var(--rounded-box, 1rem);
  border-bottom-right-radius: var(--rounded-box, 1rem);
}

.rounded-br-box {
  border-bottom-right-radius: var(--rounded-box, 1rem);
}

.rounded-tr-box {
  border-top-right-radius: var(--rounded-box, 1rem);
}

.rounded-bl-box {
  border-bottom-left-radius: var(--rounded-box, 1rem);
}

.rounded-tl-box {
  border-top-left-radius: var(--rounded-box, 1rem);
}

.rounded-btn {
  border-radius: var(--rounded-btn, .5rem);
}

.rounded-badge {
  border-radius: var(--rounded-badge, 1.9rem);
}

.glass, .glass.btn-active {
  -webkit-backdrop-filter: blur(var(--glass-blur, 40px));
  backdrop-filter: blur(var(--glass-blur, 40px));
  background-color: #0000;
  background-image: linear-gradient(135deg, rgb(255 255 255 / var(--glass-opacity, 30%))0%, #0000 100%), linear-gradient(var(--glass-reflex-degree, 100deg), rgb(255 255 255 / var(--glass-reflex-opacity, 10%))25%, #0000 25%);
  box-shadow: 0 0 0 1px rgb(255 255 255 / var(--glass-border-opacity, 10%))inset, 0 0 0 2px #0000000d;
  text-shadow: 0 1px rgb(0 0 0 / var(--glass-text-shadow-opacity, 5%));
  border: none;
}

@media (hover: hover) {
  .glass.btn-active {
    -webkit-backdrop-filter: blur(var(--glass-blur, 40px));
    backdrop-filter: blur(var(--glass-blur, 40px));
    background-color: #0000;
    background-image: linear-gradient(135deg, rgb(255 255 255 / var(--glass-opacity, 30%))0%, #0000 100%), linear-gradient(var(--glass-reflex-degree, 100deg), rgb(255 255 255 / var(--glass-reflex-opacity, 10%))25%, #0000 25%);
    box-shadow: 0 0 0 1px rgb(255 255 255 / var(--glass-border-opacity, 10%))inset, 0 0 0 2px #0000000d;
    text-shadow: 0 1px rgb(0 0 0 / var(--glass-text-shadow-opacity, 5%));
    border: none;
  }
}

.min-h-6 {
  min-height: 1.5rem;
}

.min-h-8 {
  min-height: 2rem;
}

.min-h-12 {
  min-height: 3rem;
}

.min-h-16 {
  min-height: 4rem;
}

.no-animation {
  --btn-focus-scale: 1;
  --animation-btn: 0;
  --animation-input: 0;
}

.tab-border-none {
  --tab-border: 0px;
}

.tab-border {
  --tab-border: 1px;
}

.tab-border-2 {
  --tab-border: 2px;
}

.tab-border-3 {
  --tab-border: 3px;
}

.tab-rounded-none {
  --tab-radius: 0;
}

.tab-rounded-lg {
  --tab-radius: .5rem;
}

.artboard-demo {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  display: flex;
}

.artboard.phone {
  width: 320px;
}

.artboard.phone-1 {
  width: 320px;
  height: 568px;
}

.artboard.phone-1.horizontal, .artboard.phone-1.artboard-horizontal {
  width: 568px;
  height: 320px;
}

.artboard.phone-2 {
  width: 375px;
  height: 667px;
}

.artboard.phone-2.horizontal, .artboard.phone-2.artboard-horizontal {
  width: 667px;
  height: 375px;
}

.artboard.phone-3 {
  width: 414px;
  height: 736px;
}

.artboard.phone-3.horizontal, .artboard.phone-3.artboard-horizontal {
  width: 736px;
  height: 414px;
}

.artboard.phone-4 {
  width: 375px;
  height: 812px;
}

.artboard.phone-4.horizontal, .artboard.phone-4.artboard-horizontal {
  width: 812px;
  height: 375px;
}

.artboard.phone-5 {
  width: 414px;
  height: 896px;
}

.artboard.phone-5.horizontal, .artboard.phone-5.artboard-horizontal {
  width: 896px;
  height: 414px;
}

.artboard.phone-6 {
  width: 320px;
  height: 1024px;
}

.artboard.phone-6.horizontal, .artboard.phone-6.artboard-horizontal {
  width: 1024px;
  height: 320px;
}

.badge-xs {
  height: .75rem;
  padding-left: .313rem;
  padding-right: .313rem;
  font-size: .75rem;
  line-height: .75rem;
}

.badge-sm {
  height: 1rem;
  padding-left: .438rem;
  padding-right: .438rem;
  font-size: .75rem;
  line-height: 1rem;
}

.badge-md {
  height: 1.25rem;
  padding-left: .563rem;
  padding-right: .563rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.badge-lg {
  height: 1.5rem;
  padding-left: .688rem;
  padding-right: .688rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

.btm-nav-xs {
  height: 2.5rem;
}

.btm-nav-xs > :where(.active) {
  border-top-width: 1px;
}

.btm-nav-xs .btm-nav-label {
  font-size: .75rem;
  line-height: 1rem;
}

.btm-nav-sm {
  height: 3rem;
}

.btm-nav-sm > :where(.active) {
  border-top-width: 2px;
}

.btm-nav-sm .btm-nav-label {
  font-size: .75rem;
  line-height: 1rem;
}

.btm-nav-md {
  height: 4rem;
}

.btm-nav-md > :where(.active) {
  border-top-width: 2px;
}

.btm-nav-md .btm-nav-label {
  font-size: .875rem;
  line-height: 1.25rem;
}

.btm-nav-lg {
  height: 5rem;
}

.btm-nav-lg > :where(.active) {
  border-top-width: 4px;
}

.btm-nav-lg .btm-nav-label {
  font-size: 1rem;
  line-height: 1.5rem;
}

.btn-xs {
  height: 1.5rem;
  min-height: 1.5rem;
  padding-left: .5rem;
  padding-right: .5rem;
  font-size: .75rem;
}

.btn-sm {
  height: 2rem;
  min-height: 2rem;
  padding-left: .75rem;
  padding-right: .75rem;
  font-size: .875rem;
}

.btn-md {
  height: 3rem;
  min-height: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: .875rem;
}

.btn-lg {
  height: 4rem;
  min-height: 4rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-size: 1.125rem;
}

.btn-wide {
  width: 16rem;
}

.btn-block {
  width: 100%;
}

.btn-square:where(.btn-xs) {
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
}

.btn-square:where(.btn-sm) {
  width: 2rem;
  height: 2rem;
  padding: 0;
}

.btn-square:where(.btn-md) {
  width: 3rem;
  height: 3rem;
  padding: 0;
}

.btn-square:where(.btn-lg) {
  width: 4rem;
  height: 4rem;
  padding: 0;
}

.btn-circle:where(.btn-xs) {
  border-radius: 9999px;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
}

.btn-circle:where(.btn-sm) {
  border-radius: 9999px;
  width: 2rem;
  height: 2rem;
  padding: 0;
}

.btn-circle:where(.btn-md) {
  border-radius: 9999px;
  width: 3rem;
  height: 3rem;
  padding: 0;
}

.btn-circle:where(.btn-lg) {
  border-radius: 9999px;
  width: 4rem;
  height: 4rem;
  padding: 0;
}

.btn-group-horizontal {
  flex-direction: row;
}

.btn-group-vertical {
  flex-direction: column;
}

.card-side {
  flex-direction: row;
  align-items: stretch;
}

.card-side :where(figure:first-child) {
  border-start-start-radius: inherit;
  border-start-end-radius: unset;
  border-end-end-radius: unset;
  border-end-start-radius: inherit;
  overflow: hidden;
}

.card-side :where(figure:last-child) {
  border-start-start-radius: unset;
  border-start-end-radius: inherit;
  border-end-end-radius: inherit;
  border-end-start-radius: unset;
  overflow: hidden;
}

.card-side figure > * {
  max-width: unset;
}

:where(.card-side figure > *) {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.checkbox-xs {
  width: 1rem;
  height: 1rem;
}

.checkbox-sm {
  width: 1.25rem;
  height: 1.25rem;
}

.checkbox-md {
  width: 1.5rem;
  height: 1.5rem;
}

.checkbox-lg {
  width: 2rem;
  height: 2rem;
}

.divider-horizontal {
  flex-direction: column;
}

.divider-horizontal:before, .divider-horizontal:after {
  width: .125rem;
  height: 100%;
}

.divider-vertical {
  flex-direction: row;
}

.divider-vertical:before, .divider-vertical:after {
  width: 100%;
  height: .125rem;
}

.drawer-open > .drawer-toggle {
  display: none;
}

.drawer-open > .drawer-toggle ~ .drawer-side {
  pointer-events: auto;
  visibility: visible;
  overscroll-behavior: auto;
  width: auto;
  display: block;
  position: sticky;
}

.drawer-open > .drawer-toggle ~ .drawer-side > :not(.drawer-overlay), [dir="rtl"] .drawer-open > .drawer-toggle ~ .drawer-side > :not(.drawer-overlay) {
  transform: translateX(0%);
}

.drawer-open > .drawer-toggle:checked ~ .drawer-side {
  pointer-events: auto;
  visibility: visible;
}

.file-input-xs {
  height: 1.5rem;
  padding-right: .5rem;
  font-size: .75rem;
  line-height: 1.625;
}

.file-input-xs::file-selector-button {
  height: 1.375rem;
  min-height: 1.375rem;
  margin-right: .5rem;
  font-size: .75rem;
}

.file-input-sm {
  height: 2rem;
  padding-right: .75rem;
  font-size: .875rem;
  line-height: 2;
}

.file-input-sm::file-selector-button {
  height: 1.875rem;
  min-height: 1.875rem;
  margin-right: .75rem;
  font-size: .875rem;
}

.file-input-md {
  height: 3rem;
  padding-right: 1rem;
  font-size: .875rem;
  line-height: 2;
}

.file-input-md::file-selector-button {
  height: 2.875rem;
  min-height: 2.875rem;
  margin-right: 1rem;
  font-size: .875rem;
}

.file-input-lg {
  height: 4rem;
  padding-right: 1.5rem;
  font-size: 1.125rem;
  line-height: 2;
}

.file-input-lg::file-selector-button {
  height: 3.875rem;
  min-height: 3.875rem;
  margin-right: 1.5rem;
  font-size: 1.125rem;
}

.indicator :where(.indicator-item) {
  --tw-translate-x: 50%;
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  inset: 0 0 auto auto;
}

.indicator :where(.indicator-item.indicator-start) {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  left: 0;
  right: auto;
}

.indicator :where(.indicator-item.indicator-center) {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  left: 50%;
  right: 50%;
}

.indicator :where(.indicator-item.indicator-end) {
  --tw-translate-x: 50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  left: auto;
  right: 0;
}

.indicator :where(.indicator-item.indicator-bottom) {
  --tw-translate-y: 50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  top: auto;
  bottom: 0;
}

.indicator :where(.indicator-item.indicator-middle) {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  top: 50%;
  bottom: 50%;
}

.indicator :where(.indicator-item.indicator-top) {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  top: 0;
  bottom: auto;
}

.input-xs {
  height: 1.5rem;
  padding-left: .5rem;
  padding-right: .5rem;
  font-size: .75rem;
  line-height: 1.625;
}

.input-md {
  height: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: .875rem;
  line-height: 2;
}

.input-lg {
  height: 4rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-size: 1.125rem;
  line-height: 2;
}

.input-sm {
  height: 2rem;
  padding-left: .75rem;
  padding-right: .75rem;
  font-size: .875rem;
  line-height: 2rem;
}

.join.join-vertical {
  flex-direction: column;
}

.join.join-vertical .join-item:first-child:not(:last-child), .join.join-vertical :first-child:not(:last-child) .join-item {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.join.join-vertical .join-item:last-child:not(:first-child), .join.join-vertical :last-child:not(:first-child) .join-item {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.join.join-horizontal {
  flex-direction: row;
}

.join.join-horizontal .join-item:first-child:not(:last-child), .join.join-horizontal :first-child:not(:last-child) .join-item {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: inherit;
  border-top-left-radius: inherit;
}

.join.join-horizontal .join-item:last-child:not(:first-child), .join.join-horizontal :last-child:not(:first-child) .join-item {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: inherit;
  border-top-right-radius: inherit;
}

.kbd-xs {
  min-width: 1.2em;
  min-height: 1.2em;
  padding-left: .25rem;
  padding-right: .25rem;
  font-size: .75rem;
  line-height: 1rem;
}

.kbd-sm {
  min-width: 1.6em;
  min-height: 1.6em;
  padding-left: .25rem;
  padding-right: .25rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.kbd-md {
  min-width: 2.2em;
  min-height: 2.2em;
  padding-left: .5rem;
  padding-right: .5rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

.kbd-lg {
  min-width: 2.5em;
  min-height: 2.5em;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.menu-horizontal {
  flex-direction: row;
  display: inline-flex;
}

.menu-horizontal > li:not(.menu-title) > details > ul {
  position: absolute;
}

.menu-vertical {
  flex-direction: column;
  display: flex;
}

.menu-vertical > li:not(.menu-title) > details > ul {
  position: relative;
}

.modal-top {
  place-items: start;
}

.modal-middle {
  place-items: center;
}

.modal-bottom {
  place-items: end;
}

.radio-xs {
  width: 1rem;
  height: 1rem;
}

.radio-sm {
  width: 1.25rem;
  height: 1.25rem;
}

.radio-md {
  width: 1.5rem;
  height: 1.5rem;
}

.radio-lg {
  width: 2rem;
  height: 2rem;
}

.range-xs {
  height: 1rem;
}

.range-xs::-webkit-slider-runnable-track {
  height: .25rem;
}

.range-xs::-moz-range-track {
  height: .25rem;
}

.range-xs::-webkit-slider-thumb {
  --filler-offset: .4rem;
  width: 1rem;
  height: 1rem;
}

.range-xs::-moz-range-thumb {
  --filler-offset: .4rem;
  width: 1rem;
  height: 1rem;
}

.range-sm {
  height: 1.25rem;
}

.range-sm::-webkit-slider-runnable-track {
  height: .25rem;
}

.range-sm::-moz-range-track {
  height: .25rem;
}

.range-sm::-webkit-slider-thumb {
  --filler-offset: .5rem;
  width: 1.25rem;
  height: 1.25rem;
}

.range-sm::-moz-range-thumb {
  --filler-offset: .5rem;
  width: 1.25rem;
  height: 1.25rem;
}

.range-md {
  height: 1.5rem;
}

.range-md::-webkit-slider-runnable-track {
  height: .5rem;
}

.range-md::-moz-range-track {
  height: .5rem;
}

.range-md::-webkit-slider-thumb {
  --filler-offset: .6rem;
  width: 1.5rem;
  height: 1.5rem;
}

.range-md::-moz-range-thumb {
  --filler-offset: .6rem;
  width: 1.5rem;
  height: 1.5rem;
}

.range-lg {
  height: 2rem;
}

.range-lg::-webkit-slider-runnable-track {
  height: 1rem;
}

.range-lg::-moz-range-track {
  height: 1rem;
}

.range-lg::-webkit-slider-thumb {
  --filler-offset: 1rem;
  width: 2rem;
  height: 2rem;
}

.range-lg::-moz-range-thumb {
  --filler-offset: 1rem;
  width: 2rem;
  height: 2rem;
}

.rating-xs input {
  width: .75rem;
  height: .75rem;
}

.rating-sm input {
  width: 1rem;
  height: 1rem;
}

.rating-md input {
  width: 1.5rem;
  height: 1.5rem;
}

.rating-lg input {
  width: 2.5rem;
  height: 2.5rem;
}

.rating-half.rating-xs input:not(.rating-hidden) {
  width: .375rem;
}

.rating-half.rating-sm input:not(.rating-hidden) {
  width: .5rem;
}

.rating-half.rating-md input:not(.rating-hidden) {
  width: .75rem;
}

.rating-half.rating-lg input:not(.rating-hidden) {
  width: 1.25rem;
}

.select-md {
  height: 3rem;
  min-height: 3rem;
  padding-left: 1rem;
  padding-right: 2.5rem;
  font-size: .875rem;
  line-height: 2;
}

[dir="rtl"] .select-md {
  padding-left: 2.5rem;
  padding-right: 1rem;
}

.select-lg {
  height: 4rem;
  min-height: 4rem;
  padding-left: 1.5rem;
  padding-right: 2rem;
  font-size: 1.125rem;
  line-height: 2;
}

[dir="rtl"] .select-lg {
  padding-left: 2rem;
  padding-right: 1.5rem;
}

.select-sm {
  height: 2rem;
  min-height: 2rem;
  padding-left: .75rem;
  padding-right: 2rem;
  font-size: .875rem;
  line-height: 2rem;
}

[dir="rtl"] .select-sm {
  padding-left: 2rem;
  padding-right: .75rem;
}

.select-xs {
  height: 1.5rem;
  min-height: 1.5rem;
  padding-left: .5rem;
  padding-right: 2rem;
  font-size: .75rem;
  line-height: 1.625;
}

[dir="rtl"] .select-xs {
  padding-left: 2rem;
  padding-right: .5rem;
}

.stats-horizontal {
  grid-auto-flow: column;
}

.stats-vertical {
  grid-auto-flow: row;
}

.steps-horizontal {
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  display: inline-grid;
  overflow-x: auto;
  overflow-y: hidden;
}

.steps-horizontal .step {
  text-align: center;
  grid-template-rows: repeat(2, minmax(0, 1fr));
  grid-template-columns: repeat(1, minmax(0, 1fr));
  place-items: center;
  display: grid;
}

.steps-vertical {
  grid-auto-rows: 1fr;
  grid-auto-flow: row;
}

.steps-vertical .step {
  grid-template-rows: repeat(1, minmax(0, 1fr));
  grid-template-columns: repeat(2, minmax(0, 1fr));
  display: grid;
}

.tab-md {
  --tab-padding: 1rem;
  height: 2rem;
  font-size: .875rem;
  line-height: 2;
}

.tab-lg {
  --tab-padding: 1.25rem;
  height: 3rem;
  font-size: 1.125rem;
  line-height: 2;
}

.tab-sm {
  --tab-padding: .75rem;
  height: 1.5rem;
  font-size: .875rem;
  line-height: .75rem;
}

.tab-xs {
  --tab-padding: .5rem;
  height: 1.25rem;
  font-size: .75rem;
  line-height: .75rem;
}

.textarea-xs {
  padding: .25rem .5rem;
  font-size: .75rem;
  line-height: 1.625;
}

.textarea-sm {
  padding: .25rem .75rem;
  font-size: .875rem;
  line-height: 2rem;
}

.textarea-md {
  padding: .75rem 1rem;
  font-size: .875rem;
  line-height: 2;
}

.textarea-lg {
  padding: 1rem 1.5rem;
  font-size: 1.125rem;
  line-height: 2;
}

:where(.toast) {
  --tw-translate-x: 0px;
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  inset: auto 0 0 auto;
}

.toast:where(.toast-start) {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  left: 0;
  right: auto;
}

.toast:where(.toast-center) {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  left: 50%;
  right: 50%;
}

.toast:where(.toast-end) {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  left: auto;
  right: 0;
}

.toast:where(.toast-bottom) {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  top: auto;
  bottom: 0;
}

.toast:where(.toast-middle) {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  top: 50%;
  bottom: auto;
}

.toast:where(.toast-top) {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  top: 0;
  bottom: auto;
}

.toggle-xs {
  --handleoffset: .5rem;
  width: 1.5rem;
  height: 1rem;
}

.toggle-sm {
  --handleoffset: .75rem;
  width: 2rem;
  height: 1.25rem;
}

.toggle-md {
  --handleoffset: 1.5rem;
  width: 3rem;
  height: 1.5rem;
}

.toggle-lg {
  --handleoffset: 2rem;
  width: 4rem;
  height: 2rem;
}

.tooltip {
  --tooltip-offset: calc(100% + 1px + var(--tooltip-tail, 0px));
  display: inline-block;
  position: relative;
}

.tooltip:before {
  pointer-events: none;
  z-index: 1;
  content: var(--tw-content);
  --tw-content: attr(data-tip);
  position: absolute;
}

.tooltip:before, .tooltip-top:before {
  top: auto;
  left: 50%;
  right: auto;
  bottom: var(--tooltip-offset);
  transform: translateX(-50%);
}

.tooltip-bottom:before {
  top: var(--tooltip-offset);
  bottom: auto;
  left: 50%;
  right: auto;
  transform: translateX(-50%);
}

.tooltip-left:before {
  top: 50%;
  left: auto;
  right: var(--tooltip-offset);
  bottom: auto;
  transform: translateY(-50%);
}

.tooltip-right:before {
  top: 50%;
  left: var(--tooltip-offset);
  bottom: auto;
  right: auto;
  transform: translateY(-50%);
}

.artboard-demo {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b3) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity));
  border-radius: var(--rounded-box, 1rem);
  box-shadow: 0 1px 3px #0000001a, 0 1px 2px #0000000f;
}

.avatar.online:before {
  content: "";
  z-index: 10;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--su) / var(--tw-bg-opacity));
  outline-width: 2px;
  outline-style: solid;
  outline-color: hsl(var(--b1) / 1);
  border-radius: 9999px;
  width: 15%;
  height: 15%;
  display: block;
  position: absolute;
  top: 7%;
  right: 7%;
}

.avatar.offline:before {
  content: "";
  z-index: 10;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b3) / var(--tw-bg-opacity));
  outline-width: 2px;
  outline-style: solid;
  outline-color: hsl(var(--b1) / 1);
  border-radius: 9999px;
  width: 15%;
  height: 15%;
  display: block;
  position: absolute;
  top: 7%;
  right: 7%;
}

.btn-group .btn:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.btn-group .btn:first-child:not(:last-child) {
  border-top-left-radius: var(--rounded-btn, .5rem);
  border-top-right-radius: 0;
  border-bottom-left-radius: var(--rounded-btn, .5rem);
  border-bottom-right-radius: 0;
  margin-top: 0;
  margin-left: -1px;
}

.btn-group .btn:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: var(--rounded-btn, .5rem);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: var(--rounded-btn, .5rem);
}

.btn-group-horizontal .btn:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.btn-group-horizontal .btn:first-child:not(:last-child) {
  border-top-left-radius: var(--rounded-btn, .5rem);
  border-top-right-radius: 0;
  border-bottom-left-radius: var(--rounded-btn, .5rem);
  border-bottom-right-radius: 0;
  margin-top: 0;
  margin-left: -1px;
}

.btn-group-horizontal .btn:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: var(--rounded-btn, .5rem);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: var(--rounded-btn, .5rem);
}

.btn-group-vertical .btn:first-child:not(:last-child) {
  border-top-left-radius: var(--rounded-btn, .5rem);
  border-top-right-radius: var(--rounded-btn, .5rem);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  margin-top: -1px;
  margin-left: 0;
}

.btn-group-vertical .btn:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: var(--rounded-btn, .5rem);
  border-bottom-right-radius: var(--rounded-btn, .5rem);
}

.card-compact .card-body {
  padding: 1rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.card-compact .card-title {
  margin-bottom: .25rem;
}

.card-normal .card-body {
  padding: var(--padding-card, 2rem);
  font-size: 1rem;
  line-height: 1.5rem;
}

.card-normal .card-title {
  margin-bottom: .75rem;
}

.divider-horizontal {
  width: 1rem;
  height: auto;
  margin: 0 1rem;
}

.divider-vertical {
  width: auto;
  height: 1rem;
  margin: 1rem 0;
}

.drawer-open > .drawer-toggle ~ .drawer-side > .drawer-overlay {
  cursor: default;
  background-color: #0000;
}

.join.join-vertical > :where(:not(:first-child)) {
  margin-top: -1px;
  margin-left: 0;
  margin-right: 0;
}

.join.join-horizontal > :where(:not(:first-child)) {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: -1px;
}

.menu-horizontal > li:not(.menu-title) > details > ul {
  margin-top: 1rem;
  margin-left: 0;
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-right: .5rem;
}

.menu-horizontal > li > details > ul:before {
  content: none;
}

:where(.menu-horizontal > li:not(.menu-title) > details > ul) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b1) / var(--tw-bg-opacity));
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: var(--rounded-box, 1rem);
}

.menu-vertical > li:not(.menu-title) > details > ul {
  margin-top: 0;
  margin-left: 1rem;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0;
}

.menu-xs :where(li:not(.menu-title) > :not(ul):not(details):not(.menu-title)), .menu-xs :where(li:not(.menu-title) > details > summary:not(.menu-title)) {
  border-radius: .25rem;
  padding: .25rem .5rem;
  font-size: .75rem;
  line-height: 1rem;
}

.menu-xs .menu-title {
  padding: .25rem .5rem;
}

.menu-sm :where(li:not(.menu-title) > :not(ul):not(details):not(.menu-title)), .menu-sm :where(li:not(.menu-title) > details > summary:not(.menu-title)) {
  border-radius: var(--rounded-btn, .5rem);
  padding: .25rem .75rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.menu-sm .menu-title {
  padding: .5rem .75rem;
}

.menu-md :where(li:not(.menu-title) > :not(ul):not(details):not(.menu-title)), .menu-md :where(li:not(.menu-title) > details > summary:not(.menu-title)) {
  border-radius: var(--rounded-btn, .5rem);
  padding: .5rem 1rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.menu-md .menu-title {
  padding: .5rem 1rem;
}

.menu-lg :where(li:not(.menu-title) > :not(ul):not(details):not(.menu-title)), .menu-lg :where(li:not(.menu-title) > details > summary:not(.menu-title)) {
  border-radius: var(--rounded-btn, .5rem);
  padding: .75rem 1.5rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.menu-lg .menu-title {
  padding: .75rem 1.5rem;
}

.modal-top :where(.modal-box) {
  --tw-translate-y: -2.5rem;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: var(--rounded-box, 1rem);
  border-bottom-right-radius: var(--rounded-box, 1rem);
  width: 100%;
  max-width: none;
}

.modal-middle :where(.modal-box) {
  --tw-translate-y: 0px;
  --tw-scale-x: .9;
  --tw-scale-y: .9;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  border-top-left-radius: var(--rounded-box, 1rem);
  border-top-right-radius: var(--rounded-box, 1rem);
  border-bottom-left-radius: var(--rounded-box, 1rem);
  border-bottom-right-radius: var(--rounded-box, 1rem);
  width: 91.6667%;
  max-width: 32rem;
}

.modal-bottom :where(.modal-box) {
  --tw-translate-y: 2.5rem;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: var(--rounded-box, 1rem);
  border-top-right-radius: var(--rounded-box, 1rem);
  width: 100%;
  max-width: none;
}

.stats-horizontal > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(1px * var(--tw-divide-x-reverse));
  border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
  --tw-divide-y-reverse: 0;
  border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
}

.stats-horizontal {
  overflow-x: auto;
}

.stats-vertical > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  --tw-divide-x-reverse: 0;
  border-right-width: calc(0px * var(--tw-divide-x-reverse));
  border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse)));
}

.stats-vertical {
  overflow-y: auto;
}

.steps-horizontal .step {
  grid-template-rows: 40px 1fr;
  grid-template-columns: auto;
  min-width: 4rem;
}

.steps-horizontal .step:before {
  --tw-translate-y: 0px;
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  content: "";
  width: 100%;
  height: .5rem;
  margin-left: -100%;
}

.steps-vertical .step {
  grid-template-rows: auto;
  grid-template-columns: 40px 1fr;
  justify-items: start;
  gap: .5rem;
  min-height: 4rem;
}

.steps-vertical .step:before {
  --tw-translate-y: -50%;
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  width: .5rem;
  height: 100%;
  margin-left: 50%;
}

[dir="rtl"] .steps-vertical .step:before {
  margin-right: auto;
}

.table-xs :not(thead):not(tfoot) tr {
  font-size: .75rem;
  line-height: 1rem;
}

.table-xs :where(th, td) {
  padding: .25rem .5rem;
}

.table-sm :not(thead):not(tfoot) tr {
  font-size: .875rem;
  line-height: 1.25rem;
}

.table-sm :where(th, td) {
  padding: .5rem .75rem;
}

.table-md :not(thead):not(tfoot) tr {
  font-size: .875rem;
  line-height: 1.25rem;
}

.table-md :where(th, td) {
  padding: .75rem 1rem;
}

.table-lg :not(thead):not(tfoot) tr {
  font-size: 1rem;
  line-height: 1.5rem;
}

.table-lg :where(th, td) {
  padding: 1rem 1.5rem;
}

.tooltip {
  text-align: center;
  --tooltip-tail: .1875rem;
  --tooltip-color: hsl(var(--n));
  --tooltip-text-color: hsl(var(--nc));
  --tooltip-tail-offset: calc(100% + .0625rem - var(--tooltip-tail));
  display: inline-block;
  position: relative;
}

.tooltip:before, .tooltip:after {
  opacity: 0;
  transition: color .2s cubic-bezier(.4, 0, .2, 1) .1s, background-color .2s cubic-bezier(.4, 0, .2, 1) .1s, border-color .2s cubic-bezier(.4, 0, .2, 1) .1s, text-decoration-color .2s cubic-bezier(.4, 0, .2, 1) .1s, fill .2s cubic-bezier(.4, 0, .2, 1) .1s, stroke .2s cubic-bezier(.4, 0, .2, 1) .1s, opacity .2s cubic-bezier(.4, 0, .2, 1) .1s, box-shadow .2s cubic-bezier(.4, 0, .2, 1) .1s, transform .2s cubic-bezier(.4, 0, .2, 1) .1s, filter .2s cubic-bezier(.4, 0, .2, 1) .1s, -webkit-backdrop-filter .2s cubic-bezier(.4, 0, .2, 1) .1s, backdrop-filter .2s cubic-bezier(.4, 0, .2, 1) .1s;
}

.tooltip:after {
  content: "";
  border-style: solid;
  border-width: var(--tooltip-tail, 0);
  width: 0;
  height: 0;
  display: block;
  position: absolute;
}

.tooltip:before {
  background-color: var(--tooltip-color);
  color: var(--tooltip-text-color);
  border-radius: .25rem;
  width: max-content;
  max-width: 20rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.tooltip.tooltip-open:before, .tooltip.tooltip-open:after, .tooltip:hover:before, .tooltip:hover:after, .tooltip:has(:focus-visible):after, .tooltip:has(:focus-visible):before {
  opacity: 1;
  transition-delay: 75ms;
}

.tooltip:not([data-tip]):hover:before, .tooltip:not([data-tip]):hover:after {
  visibility: hidden;
  opacity: 0;
}

.tooltip:after, .tooltip-top:after {
  border-color: var(--tooltip-color) transparent transparent transparent;
  top: auto;
  left: 50%;
  right: auto;
  bottom: var(--tooltip-tail-offset);
  transform: translateX(-50%);
}

.tooltip-bottom:after {
  border-color: transparent transparent var(--tooltip-color) transparent;
  top: var(--tooltip-tail-offset);
  bottom: auto;
  left: 50%;
  right: auto;
  transform: translateX(-50%);
}

.tooltip-left:after {
  border-color: transparent transparent transparent var(--tooltip-color);
  top: 50%;
  left: auto;
  right: calc(var(--tooltip-tail-offset)  + .0625rem);
  bottom: auto;
  transform: translateY(-50%);
}

.tooltip-right:after {
  border-color: transparent var(--tooltip-color) transparent transparent;
  top: 50%;
  left: calc(var(--tooltip-tail-offset)  + .0625rem);
  bottom: auto;
  right: auto;
  transform: translateY(-50%);
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.not-sr-only {
  clip: auto;
  white-space: normal;
  width: auto;
  height: auto;
  margin: 0;
  padding: 0;
  position: static;
  overflow: visible;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.collapse {
  visibility: collapse;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.-inset-1 {
  inset: -.25rem;
}

.left-5 {
  left: 1.25rem;
}

.right-5 {
  right: 1.25rem;
}

.top-1\/2 {
  top: 50%;
}

.isolate {
  isolation: isolate;
}

.isolation-auto {
  isolation: auto;
}

.z-10 {
  z-index: 10;
}

.z-\[1\] {
  z-index: 1;
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.col-span-4 {
  grid-column: span 4 / span 4;
}

.mx-1 {
  margin-left: .25rem;
  margin-right: .25rem;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-3 {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.ml-1 {
  margin-left: .25rem;
}

.ml-2 {
  margin-left: .5rem;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-2\.5 {
  margin-right: .625rem;
}

.mr-8 {
  margin-right: 2rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.inline-table {
  display: inline-table;
}

.table-caption {
  display: table-caption;
}

.table-cell {
  display: table-cell;
}

.table-column {
  display: table-column;
}

.table-column-group {
  display: table-column-group;
}

.table-footer-group {
  display: table-footer-group;
}

.table-header-group {
  display: table-header-group;
}

.table-row-group {
  display: table-row-group;
}

.table-row {
  display: table-row;
}

.flow-root {
  display: flow-root;
}

.grid {
  display: grid;
}

.inline-grid {
  display: inline-grid;
}

.contents {
  display: contents;
}

.list-item {
  display: list-item;
}

.hidden {
  display: none;
}

.h-10 {
  height: 2.5rem;
}

.h-14 {
  height: 3.5rem;
}

.h-2 {
  height: .5rem;
}

.h-2\.5 {
  height: .625rem;
}

.h-24 {
  height: 6rem;
}

.h-3 {
  height: .75rem;
}

.h-32 {
  height: 8rem;
}

.h-4 {
  height: 1rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-8 {
  height: 2rem;
}

.h-fit {
  height: -moz-fit-content;
  height: fit-content;
}

.h-full {
  height: 100%;
}

.min-h-full {
  min-height: 100%;
}

.w-1\/2 {
  width: 50%;
}

.w-10 {
  width: 2.5rem;
}

.w-11\/12 {
  width: 91.6667%;
}

.w-14 {
  width: 3.5rem;
}

.w-2 {
  width: .5rem;
}

.w-2\.5 {
  width: .625rem;
}

.w-24 {
  width: 6rem;
}

.w-3 {
  width: .75rem;
}

.w-32 {
  width: 8rem;
}

.w-4 {
  width: 1rem;
}

.w-5 {
  width: 1.25rem;
}

.w-52 {
  width: 13rem;
}

.w-6 {
  width: 1.5rem;
}

.w-8 {
  width: 2rem;
}

.w-full {
  width: 100%;
}

.max-w-5xl {
  max-width: 64rem;
}

.max-w-full {
  max-width: 100%;
}

.max-w-md {
  max-width: 28rem;
}

.flex-1 {
  flex: 1;
}

.shrink {
  flex-shrink: 1;
}

.flex-grow, .grow {
  flex-grow: 1;
}

.border-collapse {
  border-collapse: collapse;
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: 1s linear infinite spin;
}

.cursor-pointer {
  cursor: pointer;
}

.resize {
  resize: both;
}

.appearance-none {
  appearance: none;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-center {
  align-items: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.gap-2 {
  gap: .5rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-5 {
  gap: 1.25rem;
}

.-space-x-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-1.5rem * var(--tw-space-x-reverse));
  margin-left: calc(-1.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}

.space-y-reverse > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 1;
}

.space-x-reverse > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 1;
}

.divide-x > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(1px * var(--tw-divide-x-reverse));
  border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.divide-y-reverse > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 1;
}

.divide-x-reverse > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 1;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-ellipsis {
  text-overflow: ellipsis;
}

.text-clip {
  text-overflow: clip;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.break-words {
  overflow-wrap: break-word;
}

.break-all {
  word-break: break-all;
}

.rounded {
  border-radius: .25rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-b {
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.rounded-e {
  border-start-end-radius: .25rem;
  border-end-end-radius: .25rem;
}

.rounded-l {
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.rounded-r {
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

.rounded-s {
  border-start-start-radius: .25rem;
  border-end-start-radius: .25rem;
}

.rounded-t {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.rounded-bl {
  border-bottom-left-radius: .25rem;
}

.rounded-br {
  border-bottom-right-radius: .25rem;
}

.rounded-ee {
  border-end-end-radius: .25rem;
}

.rounded-es {
  border-end-start-radius: .25rem;
}

.rounded-se {
  border-start-end-radius: .25rem;
}

.rounded-ss {
  border-start-start-radius: .25rem;
}

.rounded-tl {
  border-top-left-radius: .25rem;
}

.rounded-tr {
  border-top-right-radius: .25rem;
}

.border {
  border-width: 1px;
}

.border-x {
  border-left-width: 1px;
  border-right-width: 1px;
}

.border-y {
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-e {
  border-inline-end-width: 1px;
}

.border-l {
  border-left-width: 1px;
}

.border-r {
  border-right-width: 1px;
}

.border-s {
  border-inline-start-width: 1px;
}

.border-t {
  border-top-width: 1px;
}

.border-accent {
  --tw-border-opacity: 1;
  border-color: hsl(var(--a) / var(--tw-border-opacity));
}

.border-base-100 {
  --tw-border-opacity: 1;
  border-color: hsl(var(--b1) / var(--tw-border-opacity));
}

.border-base-200 {
  --tw-border-opacity: 1;
  border-color: hsl(var(--b2) / var(--tw-border-opacity));
}

.border-base-300 {
  --tw-border-opacity: 1;
  border-color: hsl(var(--b3) / var(--tw-border-opacity));
}

.border-error {
  --tw-border-opacity: 1;
  border-color: hsl(var(--er) / var(--tw-border-opacity));
}

.border-info {
  --tw-border-opacity: 1;
  border-color: hsl(var(--in) / var(--tw-border-opacity));
}

.border-neutral {
  --tw-border-opacity: 1;
  border-color: hsl(var(--n) / var(--tw-border-opacity));
}

.border-primary {
  --tw-border-opacity: 1;
  border-color: hsl(var(--p) / var(--tw-border-opacity));
}

.border-secondary {
  --tw-border-opacity: 1;
  border-color: hsl(var(--s) / var(--tw-border-opacity));
}

.border-slate-800 {
  --tw-border-opacity: 1;
  border-color: rgb(30 41 59 / var(--tw-border-opacity));
}

.border-success {
  --tw-border-opacity: 1;
  border-color: hsl(var(--su) / var(--tw-border-opacity));
}

.border-warning {
  --tw-border-opacity: 1;
  border-color: hsl(var(--wa) / var(--tw-border-opacity));
}

.bg-accent {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--a) / var(--tw-bg-opacity));
}

.bg-base-100 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b1) / var(--tw-bg-opacity));
}

.bg-base-200 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2) / var(--tw-bg-opacity));
}

.bg-base-300 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b3) / var(--tw-bg-opacity));
}

.bg-error {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--er) / var(--tw-bg-opacity));
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity));
}

.bg-info {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--in) / var(--tw-bg-opacity));
}

.bg-neutral {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--n) / var(--tw-bg-opacity));
}

.bg-neutral-focus {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nf) / var(--tw-bg-opacity));
}

.bg-primary {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--p) / var(--tw-bg-opacity));
}

.bg-secondary {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--s) / var(--tw-bg-opacity));
}

.bg-success {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--su) / var(--tw-bg-opacity));
}

.bg-warning {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--wa) / var(--tw-bg-opacity));
}

.bg-repeat {
  background-repeat: repeat;
}

.fill-blue-600 {
  fill: #2563eb;
}

.p-0 {
  padding: 0;
}

.p-2 {
  padding: .5rem;
}

.p-3 {
  padding: .75rem;
}

.p-4 {
  padding: 1rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pl-5 {
  padding-left: 1.25rem;
}

.pt-10 {
  padding-top: 2.5rem;
}

.text-center {
  text-align: center;
}

.font-mono {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.normal-case {
  text-transform: none;
}

.italic {
  font-style: italic;
}

.not-italic {
  font-style: normal;
}

.normal-nums {
  font-variant-numeric: normal;
}

.ordinal {
  --tw-ordinal: ordinal;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
}

.slashed-zero {
  --tw-slashed-zero: slashed-zero;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
}

.lining-nums {
  --tw-numeric-figure: lining-nums;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
}

.oldstyle-nums {
  --tw-numeric-figure: oldstyle-nums;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
}

.proportional-nums {
  --tw-numeric-spacing: proportional-nums;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
}

.tabular-nums {
  --tw-numeric-spacing: tabular-nums;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
}

.diagonal-fractions {
  --tw-numeric-fraction: diagonal-fractions;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
}

.tracking-tight {
  letter-spacing: -.025em;
}

.text-accent {
  --tw-text-opacity: 1;
  color: hsl(var(--a) / var(--tw-text-opacity));
}

.text-accent-content {
  --tw-text-opacity: 1;
  color: hsl(var(--ac) / var(--tw-text-opacity));
}

.text-error {
  --tw-text-opacity: 1;
  color: hsl(var(--er) / var(--tw-text-opacity));
}

.text-error-content {
  --tw-text-opacity: 1;
  color: hsl(var(--erc) / var(--tw-text-opacity));
}

.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.text-info {
  --tw-text-opacity: 1;
  color: hsl(var(--in) / var(--tw-text-opacity));
}

.text-info-content {
  --tw-text-opacity: 1;
  color: hsl(var(--inc) / var(--tw-text-opacity));
}

.text-neutral {
  --tw-text-opacity: 1;
  color: hsl(var(--n) / var(--tw-text-opacity));
}

.text-neutral-content {
  --tw-text-opacity: 1;
  color: hsl(var(--nc) / var(--tw-text-opacity));
}

.text-primary {
  --tw-text-opacity: 1;
  color: hsl(var(--p) / var(--tw-text-opacity));
}

.text-primary-content {
  --tw-text-opacity: 1;
  color: hsl(var(--pc) / var(--tw-text-opacity));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

.text-secondary {
  --tw-text-opacity: 1;
  color: hsl(var(--s) / var(--tw-text-opacity));
}

.text-secondary-content {
  --tw-text-opacity: 1;
  color: hsl(var(--sc) / var(--tw-text-opacity));
}

.text-slate-400 {
  --tw-text-opacity: 1;
  color: rgb(148 163 184 / var(--tw-text-opacity));
}

.text-slate-500 {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity));
}

.text-success {
  --tw-text-opacity: 1;
  color: hsl(var(--su) / var(--tw-text-opacity));
}

.text-success-content {
  --tw-text-opacity: 1;
  color: hsl(var(--suc) / var(--tw-text-opacity));
}

.text-warning {
  --tw-text-opacity: 1;
  color: hsl(var(--wa) / var(--tw-text-opacity));
}

.text-warning-content {
  --tw-text-opacity: 1;
  color: hsl(var(--wac) / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.overline {
  text-decoration-line: overline;
}

.line-through {
  text-decoration-line: line-through;
}

.no-underline {
  text-decoration-line: none;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.subpixel-antialiased {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

.opacity-0 {
  opacity: 0;
}

.opacity-50 {
  opacity: .5;
}

.opacity-60 {
  opacity: .6;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline {
  outline-style: solid;
}

.outline-offset-0 {
  outline-offset: 0px;
}

.ring {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-inset {
  --tw-ring-inset: inset;
}

.ring-accent {
  --tw-ring-opacity: 1;
  --tw-ring-color: hsl(var(--a) / var(--tw-ring-opacity));
}

.ring-error {
  --tw-ring-opacity: 1;
  --tw-ring-color: hsl(var(--er) / var(--tw-ring-opacity));
}

.ring-info {
  --tw-ring-opacity: 1;
  --tw-ring-color: hsl(var(--in) / var(--tw-ring-opacity));
}

.ring-neutral {
  --tw-ring-opacity: 1;
  --tw-ring-color: hsl(var(--n) / var(--tw-ring-opacity));
}

.ring-primary {
  --tw-ring-opacity: 1;
  --tw-ring-color: hsl(var(--p) / var(--tw-ring-opacity));
}

.ring-secondary {
  --tw-ring-opacity: 1;
  --tw-ring-color: hsl(var(--s) / var(--tw-ring-opacity));
}

.ring-success {
  --tw-ring-opacity: 1;
  --tw-ring-color: hsl(var(--su) / var(--tw-ring-opacity));
}

.ring-warning {
  --tw-ring-opacity: 1;
  --tw-ring-color: hsl(var(--wa) / var(--tw-ring-opacity));
}

.ring-offset-2 {
  --tw-ring-offset-width: 2px;
}

.ring-offset-base-100 {
  --tw-ring-offset-color: hsl(var(--b1) / 1);
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow {
  --tw-drop-shadow: drop-shadow(0 1px 2px #0000001a) drop-shadow(0 1px 1px #0000000f);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.grayscale {
  --tw-grayscale: grayscale(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.invert {
  --tw-invert: invert(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.sepia {
  --tw-sepia: sepia(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.backdrop-blur {
  --tw-backdrop-blur: blur(8px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-grayscale {
  --tw-backdrop-grayscale: grayscale(100%);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-invert {
  --tw-backdrop-invert: invert(100%);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-sepia {
  --tw-backdrop-sepia: sepia(100%);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-filter {
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-300 {
  transition-duration: .3s;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

@media (hover: hover) {
  .table .hover\:hover:hovertr:hover {
    --tw-bg-opacity: 1;
    background-color: hsl(var(--b2) / var(--tw-bg-opacity));
  }

  .table .hover\:hover:hovertr:nth-child(2n):hover {
    --tw-bg-opacity: 1;
    background-color: hsl(var(--b2) / var(--tw-bg-opacity));
  }

  .table-zebra .hover\:hover:hovertr:hover {
    --tw-bg-opacity: 1;
    background-color: hsl(var(--b3) / var(--tw-bg-opacity));
  }

  .table-zebra .hover\:hover:hovertr:nth-child(2n):hover {
    --tw-bg-opacity: 1;
    background-color: hsl(var(--b3) / var(--tw-bg-opacity));
  }
}

@media (width >= 640px) {
  .sm\:btn-sm {
    height: 2rem;
    min-height: 2rem;
    padding-left: .75rem;
    padding-right: .75rem;
    font-size: .875rem;
  }

  .btn-square:where(.sm\:btn-sm) {
    width: 2rem;
    height: 2rem;
    padding: 0;
  }

  .btn-circle:where(.sm\:btn-sm) {
    border-radius: 9999px;
    width: 2rem;
    height: 2rem;
    padding: 0;
  }

  .sm\:card-side {
    flex-direction: row;
    align-items: stretch;
  }

  .sm\:card-side :where(figure:first-child) {
    border-start-start-radius: inherit;
    border-start-end-radius: unset;
    border-end-end-radius: unset;
    border-end-start-radius: inherit;
    overflow: hidden;
  }

  .sm\:card-side :where(figure:last-child) {
    border-start-start-radius: unset;
    border-start-end-radius: inherit;
    border-end-end-radius: inherit;
    border-end-start-radius: unset;
    overflow: hidden;
  }

  .sm\:card-side figure > * {
    max-width: unset;
  }

  :where(.sm\:card-side figure > *) {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .sm\:modal-middle {
    place-items: center;
  }

  .sm\:card-compact .card-body {
    padding: 1rem;
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .sm\:card-compact .card-title {
    margin-bottom: .25rem;
  }

  .sm\:card-normal .card-body {
    padding: var(--padding-card, 2rem);
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .sm\:card-normal .card-title {
    margin-bottom: .75rem;
  }

  .sm\:modal-middle :where(.modal-box) {
    --tw-translate-y: 0px;
    --tw-scale-x: .9;
    --tw-scale-y: .9;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    border-top-left-radius: var(--rounded-box, 1rem);
    border-top-right-radius: var(--rounded-box, 1rem);
    border-bottom-left-radius: var(--rounded-box, 1rem);
    border-bottom-right-radius: var(--rounded-box, 1rem);
    width: 91.6667%;
    max-width: 32rem;
  }
}

@media (width >= 768px) {
  .md\:badge-sm {
    height: 1rem;
    padding-left: .438rem;
    padding-right: .438rem;
    font-size: .75rem;
    line-height: 1rem;
  }

  .md\:btn-md {
    height: 3rem;
    min-height: 3rem;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: .875rem;
  }

  .btn-square:where(.md\:btn-md) {
    width: 3rem;
    height: 3rem;
    padding: 0;
  }

  .btn-circle:where(.md\:btn-md) {
    border-radius: 9999px;
    width: 3rem;
    height: 3rem;
    padding: 0;
  }

  .md\:card-side {
    flex-direction: row;
    align-items: stretch;
  }

  .md\:card-side :where(figure:first-child) {
    border-start-start-radius: inherit;
    border-start-end-radius: unset;
    border-end-end-radius: unset;
    border-end-start-radius: inherit;
    overflow: hidden;
  }

  .md\:card-side :where(figure:last-child) {
    border-start-start-radius: unset;
    border-start-end-radius: inherit;
    border-end-end-radius: inherit;
    border-end-start-radius: unset;
    overflow: hidden;
  }

  .md\:card-side figure > * {
    max-width: unset;
  }

  :where(.md\:card-side figure > *) {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .md\:card-compact .card-body {
    padding: 1rem;
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .md\:card-compact .card-title {
    margin-bottom: .25rem;
  }

  .md\:card-normal .card-body {
    padding: var(--padding-card, 2rem);
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .md\:card-normal .card-title {
    margin-bottom: .75rem;
  }
}

@media (width >= 1024px) {
  .lg\:badge-md {
    height: 1.25rem;
    padding-left: .563rem;
    padding-right: .563rem;
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .lg\:btn-lg {
    height: 4rem;
    min-height: 4rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    font-size: 1.125rem;
  }

  .btn-square:where(.lg\:btn-lg) {
    width: 4rem;
    height: 4rem;
    padding: 0;
  }

  .btn-circle:where(.lg\:btn-lg) {
    border-radius: 9999px;
    width: 4rem;
    height: 4rem;
    padding: 0;
  }

  .lg\:card-side {
    flex-direction: row;
    align-items: stretch;
  }

  .lg\:card-side :where(figure:first-child) {
    border-start-start-radius: inherit;
    border-start-end-radius: unset;
    border-end-end-radius: unset;
    border-end-start-radius: inherit;
    overflow: hidden;
  }

  .lg\:card-side :where(figure:last-child) {
    border-start-start-radius: unset;
    border-start-end-radius: inherit;
    border-end-end-radius: inherit;
    border-end-start-radius: unset;
    overflow: hidden;
  }

  .lg\:card-side figure > * {
    max-width: unset;
  }

  :where(.lg\:card-side figure > *) {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .lg\:divider-horizontal {
    flex-direction: column;
  }

  .lg\:divider-horizontal:before, .lg\:divider-horizontal:after {
    width: .125rem;
    height: 100%;
  }

  .lg\:join-horizontal.join {
    flex-direction: row;
  }

  .lg\:join-horizontal.join .join-item:first-child:not(:last-child), .lg\:join-horizontal .join :first-child:not(:last-child) .join-item {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: inherit;
    border-top-left-radius: inherit;
  }

  .lg\:join-horizontal.join .join-item:last-child:not(:first-child), .lg\:join-horizontal .join :last-child:not(:first-child) .join-item {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: inherit;
    border-top-right-radius: inherit;
  }

  .lg\:menu-horizontal {
    flex-direction: row;
    display: inline-flex;
  }

  .lg\:menu-horizontal > li:not(.menu-title) > details > ul {
    position: absolute;
  }

  .lg\:card-compact .card-body {
    padding: 1rem;
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .lg\:card-compact .card-title {
    margin-bottom: .25rem;
  }

  .lg\:card-normal .card-body {
    padding: var(--padding-card, 2rem);
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .lg\:card-normal .card-title {
    margin-bottom: .75rem;
  }

  .lg\:divider-horizontal {
    width: 1rem;
    height: auto;
    margin: 0 1rem;
  }

  .lg\:join-horizontal.join > :where(:not(:first-child)) {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: -1px;
  }

  .lg\:menu-horizontal > li:not(.menu-title) > details > ul {
    margin-top: 1rem;
    margin-left: 0;
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-right: .5rem;
  }

  .lg\:menu-horizontal > li > details > ul:before {
    content: none;
  }

  :where(.lg\:menu-horizontal > li:not(.menu-title) > details > ul) {
    --tw-bg-opacity: 1;
    background-color: hsl(var(--b1) / var(--tw-bg-opacity));
    --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
    --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    border-radius: var(--rounded-box, 1rem);
  }
}

@media (width >= 1280px) {
  .xl\:badge-lg {
    height: 1.5rem;
    padding-left: .688rem;
    padding-right: .688rem;
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

.focus\:outline-offset-0:focus {
  outline-offset: 0px;
}

:is(.dark .dark\:bg-gray-700) {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}

@media (width >= 640px) {
  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media (width >= 768px) {
  .md\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .md\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .md\:mb-0 {
    margin-bottom: 0;
  }

  .md\:ml-2 {
    margin-left: .5rem;
  }

  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:items-center {
    align-items: center;
  }

  .md\:justify-start {
    justify-content: flex-start;
  }

  .md\:justify-between {
    justify-content: space-between;
  }

  .md\:p-5 {
    padding: 1.25rem;
  }

  .md\:hover\:cursor-pointer:hover {
    cursor: pointer;
  }

  .md\:hover\:bg-slate-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(30 41 59 / var(--tw-bg-opacity));
  }
}

@media (width >= 1024px) {
  .lg\:flex {
    display: flex;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

/*# sourceMappingURL=index.71660a5c.css.map */
